import { CircularProgress, Divider, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Namespace } from "../../locales/translations";
import { useAppSelector } from "../../store/hooks";
import { selectAllBatches } from "../../store/reducers/batches/batch_reducer";
import StatisticsPage from "../StatisticsPage";
import CenteredBox from "../_include/CenteredBox";
import FilterChip from "../_include/Filters/FilterChip";
import BatchCardList from "./BatchCardList";
import KeyNumbersCard from "./KeyNumbersCard";
import { selectAllRfidBatches } from "../../store/reducers/batches/rfid_batch_reducer";

type BatchListContainerProps = {
    showStats?: boolean;
    onSeeMoreClick: () => void;

};

const BatchesList: React.FC<BatchListContainerProps> = ({ showStats, onSeeMoreClick }) => {
    const hasAddresses = useAppSelector(state => state.address.ids.length > 0);
    const hasBatches = useAppSelector(state => selectAllBatches(state).length > 0 || selectAllRfidBatches(state).length > 0);
    const loading = useAppSelector(state => state.batch.list.loading || state.batch.rfid.loading);
    const error = useAppSelector(state => state.batch.list.error || state.batch.rfid.error);
    const { t } = useTranslation([Namespace.ADDRESSES]);

    if (loading) {
        return (
            <CenteredBox>
                <CircularProgress />
            </CenteredBox>
        );
    }

    if (error) {
        return (
            <CenteredBox>
                <Typography variant="body2" color="error">{error}</Typography>
            </CenteredBox>
        );
    }

    return (
        <>
            {hasBatches ? (
                <Stack direction="column" spacing={2} padding={2}>
                    {showStats ? (
                        <StatisticsPage />
                    ) : (
                        <>
                            <KeyNumbersCard  onSeeMoreClick={onSeeMoreClick} />
                            <Divider variant="fullWidth" />
                            <FilterChip />
                            <BatchCardList/>
                        </>
                    )}
                </Stack>
            ) : (
                <CenteredBox>
                    <Typography variant="h4" color="GrayText" textAlign="center">
                        {t(hasAddresses ? "select_an_address" : "no_address_match", { ns: Namespace.ADDRESSES })}
                    </Typography>
                </CenteredBox>
            )}
        </>
    );
};

export default BatchesList;
