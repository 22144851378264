import React from 'react';

const SvgMask: React.FC = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                zIndex: 1,
            }}
        >
            <mask id="mask" x="0" y="0" width="100%" height="100%">
                <rect width="100%" height="100%" fill="white" />
                {/* Transparent cutout area */}
                <rect
                    x="10%"
                    y="25%"
                    width="80%"
                    height="20%"
                    fill="black"
                    rx="12"
                />
            </mask>

            <rect
                width="100%"
                height="100%"
                fill="rgba(0, 0, 0, 0.5)"
                mask="url(#mask)"
            />
        </svg>
    );
};

export default SvgMask;
