import { EntityState, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { Batch } from '../../../models/Batches';
import { RootState } from '../../store';

const batchesAdapter = createEntityAdapter<Batch>({
    selectId: (batch) => batch.timestamp,
    sortComparer: (a, b) => b.timestamp - a.timestamp, // sort batches by timestamp desc
});

type ExtendedBatchState = EntityState<Batch> & {
    loading: boolean;
    error: string | null;
};

const batchesSlice = createSlice({
    name: 'batches',
    initialState: batchesAdapter.getInitialState({
        loading: false,
        error: null,
    }) as ExtendedBatchState,
    reducers: {
        addBatch: batchesAdapter.addOne,
        addBatches: batchesAdapter.addMany,
        updateBatch: batchesAdapter.updateOne,
        removeBatch: batchesAdapter.removeOne,
        removeAllBatches: batchesAdapter.removeAll,
        startLoading: (state) => {
            state.loading = true;
        },
        stopLoading: (state) => {
            state.loading = false;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
        clearError: (state) => {
            state.error = null;
        },
    },
});

export const BatchesActions = batchesSlice.actions;

export const {
    selectAll: selectAllBatches,
    selectById: selectBatchById,
    selectIds: selectBatchIds,
} = batchesAdapter.getSelectors((state: RootState) => state.batch.list)

const BatchesReducer = batchesSlice.reducer;

export default BatchesReducer;
