import { EntityId, PayloadAction, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { AddressStatsPointFeature, DEFAULT_ZOOM_LEVEL, GRENOBLE_COORDINATES, formatAddress } from "../../../helpers/geo";
import { getBatchKey } from "../../../helpers/strings";
import { LoadableContext, RootState } from "../../store";

const addressesPointsAdapter = createEntityAdapter<AddressStatsPointFeature>({
    selectId: point => getBatchKey(point.properties),
    sortComparer: (place1, place2) => {
        const errorsSorting = place2.properties.errorsCount - place1.properties.errorsCount;
        if (errorsSorting !== 0) return errorsSorting; // most errors first
        return place2.properties.batchesCount - place1.properties.batchesCount; // same errors: most batches first
    },
});

export interface Bounds {
    east: number;
    north: number;
    west: number;
    south: number;
}

export interface Coords {
    lat: number;
    lng: number;
}

type MapContext = LoadableContext & {
    defaultCenter: Coords;
    defaultBounds: Bounds | undefined;
    defaultZoom: number;
    selectedAddressKey: string | null;
    totalBatchesCount: number;
    filterString: string;
    filteredAddressesIDs: EntityId[];
    showSearchAreaButton: boolean;
};

const initialState: MapContext = {
    loading: false,
    error: null,
    defaultCenter: GRENOBLE_COORDINATES,
    defaultBounds: {
       east: 0,
       north: 0,
       south: 0,
       west: 0,
    },
    defaultZoom: DEFAULT_ZOOM_LEVEL,
    selectedAddressKey: null,
    totalBatchesCount: 0,
    filterString: "",
    filteredAddressesIDs: [],
    showSearchAreaButton: false,
};

export const sortingMapSlice = createSlice({
    name: 'sorting_map',
    initialState: addressesPointsAdapter.getInitialState(initialState),
    reducers: {
        startLoading: (state) => {
            state.loading = true;
            state.error = null;
            state.selectedAddressKey = null;
        },
        removeMapPoints: (state) => {
            state.loading = false;
            state.selectedAddressKey = null;
            state.totalBatchesCount = 0;
            addressesPointsAdapter.removeAll(state);
        },
        setMapPoints: (state, { payload: { bounds, points, totalBatchesCount } }: PayloadAction<{ bounds: Bounds, points: AddressStatsPointFeature[], totalBatchesCount: number }>) => {
            state.loading = false;
            state.defaultBounds = bounds;
            state.totalBatchesCount = totalBatchesCount;
            addressesPointsAdapter.setAll(state, points);
            state.filteredAddressesIDs = [...state.ids];
        },
        setMapBounds: (state, { payload: { center, bounds } }: PayloadAction<{ center: Coords, bounds: Bounds }>) => {
            state.defaultCenter = center;
            state.defaultBounds = bounds;
        },
        setCenterAndZoom: (state, { payload: { center, zoom } }: PayloadAction<{ center: Coords, zoom: number }>) => {
            state.defaultCenter = center;
            state.defaultZoom = zoom;
        },
        resetMapBounds: (state) => {
            state.defaultBounds = undefined;
        },
        /** Select a point on sorting map to display all batches associated to this address. */
        selectAddressKey: (state, { payload: addressKey }: PayloadAction<string | null>) => {
            state.selectedAddressKey = addressKey;
            if (addressKey) { // center map on selected place
                const addressPoint = state.entities[addressKey];
                if (addressPoint) {
                    const [lat, lng] = addressPoint.geometry.coordinates;
                    state.defaultCenter = { lat, lng };
                }
            }
        },
        //** Updates the center of the map */
        setDefaultCenter: (state, { payload: center }: PayloadAction<Coords>) => {
            state.defaultCenter = center;
        },
        setError: (state, { payload: error }: PayloadAction<string>) => {
            state.loading = false;
            state.error = error;
        },
        setFilterString: (state, { payload: filterString }: PayloadAction<string>) => {
            state.filterString = filterString;
            state.filteredAddressesIDs = state.ids.filter(placeID => {
                return formatAddress(state.entities[placeID]!.properties.address)
                    .toLowerCase()
                    .includes(filterString.toLowerCase());
            });
        },
        /** Indicate whether the "Search this area" button should be displayed on the Search Map. */
        toggleShowSearchAreaButton: (state, { payload: show }: PayloadAction<boolean>) => {
            state.showSearchAreaButton = show;
        },
    },
});

export const SortingMapActions = sortingMapSlice.actions;

export const {
    selectAll: selectAllSortingPoints,
    selectById: selectSortingPointByAddressKey,
    selectIds: selectAllSortingAddressesKeys,
} = addressesPointsAdapter.getSelectors((state: RootState) => state.batch.map);

const SortingMapReducer = sortingMapSlice.reducer;

export default SortingMapReducer;