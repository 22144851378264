import { Box } from "@mui/material";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router";
import BatchesList from "./AddressStats/BatchesList";
import { BackButton } from "./common/Buttons";


export const BatchDetailWrapper = () => {
    const navigate = useNavigate();
    const [showStatistics, setShowStatistics] = useState(false);

    const handleBackClick = useCallback(() => {
        if (showStatistics) {
            setShowStatistics(false);
        } else {
            navigate(-1);
        }
    }, [showStatistics, navigate]);

    return (
        <>
            <Box display="flex" alignItems="center" sx={{ position: 'relative', top: 16, left: 16, zIndex: 1000 }}>
                <BackButton onClick={handleBackClick} />
            </Box>
            <BatchesList showStats={showStatistics} onSeeMoreClick={() => setShowStatistics(true)} />
        </>
    );
};