const serverURL = process.env.REACT_APP_SERVER_URL;
const googleMapsWebsite = "https://www.google.com/maps";

export default {
    API: `${serverURL}`,
    STORAGE: "https://storage.googleapis.com",
    WEBSITE: "https://ficha.fr",
    GOOGLE_MAPS: {
        WEBSITE: googleMapsWebsite,
        PLACE: `${googleMapsWebsite}/place`,
    },
    FEEDBACK_FORM: "https://docs.google.com/forms/d/e/1FAIpQLSeglxyzUUVw4Gq_jQIE8DqDBVxYvWemSPGqyyZbRG0Ru15yOA/viewform",
    ICONS_FOLDER: `${process.env.PUBLIC_URL}/icons`,
    IMAGES_FOLDER: `${process.env.PUBLIC_URL}/images`,
    FONTS_FOLDER: `${process.env.PUBLIC_URL}/fonts`,
}