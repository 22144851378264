import { Box, Divider, Stack, SwipeableDrawer, Typography } from "@mui/material";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Namespace } from "../../locales/translations";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { selectAllBatches } from "../../store/reducers/batches/batch_reducer";
import StatisticsPage from "../StatisticsPage";
import CenteredBox from "../_include/CenteredBox";
import FilterChip from "../_include/Filters/FilterChip";
import { BackButton } from "../common/Buttons";
import BatchCardList from "./BatchCardList";
import KeyNumbersCard from "./KeyNumbersCard";
import { SortingMapActions } from "../../store/reducers/batches/map";
import SectionLoader from "../_include/SectionLoader";
import { RfidsBatchesActions, selectAllRfidBatches } from "../../store/reducers/batches/rfid_batch_reducer";


type StatsDrawerProps = {
};

const StatsDrawer: React.FC<StatsDrawerProps> = () => {
    const hasAddresses = useAppSelector(state => state.address.ids.length > 0);
    const hasBatches = useAppSelector(state => selectAllBatches(state).length > 0 || selectAllRfidBatches(state).length > 0);
    const loading = useAppSelector(state => state.batch.list.loading || state.batch.rfid.loading);
    const addressSelected = useAppSelector(state => Boolean(state.batch.map.selectedAddressKey));
    const showRfid = useAppSelector(state => state.batch.rfid.showRfidsBatches);

    /** By default the drawer should open to a height of 40vh. */
    const DEFAULT_DRAWER_HEIGHT_VH = showRfid ? 100 : 40;

    const { t } = useTranslation([Namespace.ADDRESSES]);

    const [showStatistics, setShowStatistics] = useState(false);
    const [drawerHeight, setDrawerHeight] = useState(DEFAULT_DRAWER_HEIGHT_VH);
    const isDragging = useRef(false);
    const startY = useRef(0);
    const startHeight = useRef(0);

    const dispatch = useAppDispatch();

    const closeDrawer = () => {
        dispatch(SortingMapActions.selectAddressKey(null));
        dispatch(RfidsBatchesActions.showRfidBatches(false));
    };

    const handleStart = (event: React.MouseEvent | React.TouchEvent) => {
        isDragging.current = true;
        startY.current = 'touches' in event ? event.touches[0].clientY : event.clientY;
        startHeight.current = drawerHeight;
        document.addEventListener('mousemove', handleMove);
        document.addEventListener('mouseup', handleEnd);
        document.addEventListener('touchmove', handleMove);
        document.addEventListener('touchend', handleEnd);
    };


    const handleMove = (event: MouseEvent | TouchEvent) => {
        if (!isDragging.current) return;
        const clientY = 'touches' in event ? event.touches[0].clientY : event.clientY;
        const deltaY = startY.current - clientY;
        const newHeight = Math.min(Math.max(startHeight.current + (deltaY / window.innerHeight) * 100, 0), 100);
        // close if below 25vh
        if (newHeight < 25) closeDrawer();
        setDrawerHeight(newHeight);
    };

    const handleEnd = () => {
        isDragging.current = false;
        document.removeEventListener('mousemove', handleMove);
        document.removeEventListener('mouseup', handleEnd);
        document.removeEventListener('touchmove', handleMove);
        document.removeEventListener('touchend', handleEnd);
        // Snap to 40vh or 100vh
        showRfid ? setDrawerHeight(100) : setDrawerHeight((prevHeight) => (prevHeight > 60 ? 100 : DEFAULT_DRAWER_HEIGHT_VH));

    };

    return (
        <SwipeableDrawer
            variant="persistent"
            hideBackdrop={true}
            anchor="bottom"
            open={addressSelected || showRfid}
            onClose={() => closeDrawer()}
            onOpen={() => { }}
            swipeAreaWidth={56}
            disableSwipeToOpen={false}
            ModalProps={{
                keepMounted: true,
            }}
        >
            {/** Drawer handle */}
            <Box
                display="flex"
                justifyContent="center"
                justifyItems="center"
                onTouchStart={handleStart}
                onMouseDown={handleStart}
                py={3}
            >
                <Box
                    bgcolor="rgba(0,0,0,.5)"
                    width={theme => theme.spacing(10)}
                    height={4}
                    borderRadius={2}
                />
            </Box>

            <Box
                sx={{
                    height: `${drawerHeight}vh`,
                    overflow: 'auto',
                    padding: 2,
                    paddingTop: 0,
                    backgroundColor: 'white',
                    paddingBottom: '100px',
                }}
            >
                <BackButton onClick={() => {
                    setShowStatistics(false);
                    if (!showStatistics) closeDrawer();
                }} />
                {hasBatches  ? (
                    <Stack direction="column" spacing={2}>
                        {showStatistics ? (
                            <StatisticsPage />
                        ) : (
                            <>
                                <KeyNumbersCard onSeeMoreClick={() => setShowStatistics(true)} />
                                <Divider variant="fullWidth" />
                                <FilterChip />
                                <BatchCardList />
                            </>
                        )}
                    </Stack>
                ) : (
                    <CenteredBox>
                        {loading ? (
                            <SectionLoader />
                        ) : (
                            <Typography variant="h4" color="GrayText" textAlign="center">
                                {showRfid ? t("bin_not_collected", {ns: Namespace.RFID}) : t(hasAddresses ? "select_an_address" : "no_address_match", { ns: Namespace.ADDRESSES })}
                            </Typography>
                        )}
                    </CenteredBox>
                )}
            </Box>
        </SwipeableDrawer>
    );
};

export default StatsDrawer;
