import { ListItem, Checkbox, ListItemText, Divider } from "@mui/material";
import { formatAddress } from "../../helpers/geo";
import { useTranslation } from "react-i18next";
import { Namespace } from "../../locales/translations";
import { useAppSelector } from "../../store/hooks";
import { MissionStatus } from "../../models/Missions";

type ItemProps = {
    index: number;
    onCheckboxChange: (addressKey: string) => void;
    onAddressClick: (addressKey: string) => void;
    onAddressDetailClick: (addressKey: string) => void;
}

export default function MissionAddressItem({ index, onCheckboxChange, onAddressClick, onAddressDetailClick, }: ItemProps) {
    const { t } = useTranslation([Namespace.GLOSSARY]);

    const addressData = useAppSelector(state => state.mission.selected.selectedMission?.addresses?.[index])!;

    const missionCompleted = useAppSelector(state => state.mission.selected.selectedMission?.status === MissionStatus.COMPLETED);

    const errorsCountLabel = t('errors_count', { count: addressData.errorsCount, ns: Namespace.GLOSSARY });
    const imagesCountLabel = t('images_count', { count: addressData.batchesCount, ns: Namespace.GLOSSARY });

    return (
        <>
            <ListItem
                sx={{
                    '&:hover': {
                        backgroundColor: '#f0f0f0',
                    },
                }}
            // secondaryAction={
            //     <IconButton
            //         edge="end"
            //         onClick={() => onAddressClick(addressData.addressKey)}
            //     >
            //         <GpsFixed />
            //     </IconButton>
            // }
            >
                {!missionCompleted && (
                    <Checkbox
                        edge="start"
                        disableRipple
                        checked={!!addressData.visited}
                        onChange={() => onCheckboxChange(addressData.addressKey)}
                    />
                )}
                <ListItemText
                    onClick={() => onAddressDetailClick(addressData.addressKey)}
                    primary={formatAddress(addressData.address)}
                    secondary={`${errorsCountLabel} / ${imagesCountLabel}`}
                />
            </ListItem>
            <Divider />
        </>
    )
}