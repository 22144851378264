import { Box, Paper } from "@mui/material";
import { styled } from "@mui/system";
import MostFrequentErrorsChart from "./Charts/MostFrequentErrors/MostFrequentErrorsWrapper";
import ErrorsEvolutionBarChart from "./Charts/ErrorsCountEvolution/ErrorsEvolutionBarChart";

const CHART_HEIGHT = 40;

const ChartPaper = styled(Paper)(({ theme }) => ({
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(2),
}));

function StatisticsPage() {
    return (
        <Box>
            <ChartPaper>
                <MostFrequentErrorsChart height={CHART_HEIGHT} />
            </ChartPaper>
            <ChartPaper>
                <ErrorsEvolutionBarChart />
            </ChartPaper>
        </Box>
    );
}

export default StatisticsPage;
