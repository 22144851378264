import { Box, TextField, Typography } from '@mui/material';
import { getAuth, isSignInWithEmailLink } from 'firebase/auth';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import UserMethods from '../../actions/user_action';
import { TextInput } from '../../helpers/forms';
import { isValidEmail } from '../../helpers/strings';
import { Namespace } from '../../locales/translations';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import ActionButton from '../_include/Buttons/ActionButton';

type LoginPageProps = {

}

type InputTypes = {
    email: TextInput;
}

function LoginPage(props: LoginPageProps) {
    const dispatch = useAppDispatch();
    const [searchParams] = useSearchParams();
    const emailParam = searchParams.get('email');
    const { t } = useTranslation([Namespace.ACTIONS, Namespace.FORMS, Namespace.TITLES,]);
    const userLoading = useAppSelector(state => state.user.loading);
    const userAuth = useAppSelector(state => state.user.isAuthenticated);

    const [inputs, setInputs] = useState<InputTypes>({
        email: {
            value: emailParam ?? "",
            error: null,
        },
    });

    const { value: email, error: emailError } = inputs.email;
    const signInAttemptedRef = useRef(false); // Track if sign-in was attempted

    const handleEmailChanged = (value: string) => {
        setInputs({
            ...inputs,
            email: {
                value,
                error: null,
            }
        });
    }

    const handleReceiveLinkPressed = () => {
        if (isValidEmail(email)) {
            dispatch(UserMethods.requestMagicLink(email));
        }
        else { // invalid email
            setInputs({
                ...inputs,
                email: {
                    ...inputs.email,
                    error: t("sign_in.invalid_email", { ns: Namespace.FORMS }),
                },
            });
        }
    }

    /** Login if coming from a Firebase auth magic link */
    useEffect(() => {
        const link = window.location.href;

        // log user in with Firebase auth
        const auth = getAuth();
        if (isSignInWithEmailLink(auth, link) && !userAuth && !signInAttemptedRef.current) {
            signInAttemptedRef.current = true; // Set to true to prevent multiple sign-in attempts
            
            // check if email is set in URL params
            if (!emailParam) {
                alert("Invalid link");
                return;
            }

            dispatch(UserMethods.signInWithMagicLink(emailParam, link));
        }

    }, [emailParam]);

    return (
        <Box
            display="flex"
            flexDirection="column"
            flexGrow={1}
            justifyContent="center"
            alignItems="center"
        >
            <Typography variant="h1" sx={{
                mb: 5
            }}>
                {t("dashboard", { ns: Namespace.TITLES })}
            </Typography>
            <form
                autoComplete="on"
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "stretch",
                    flexGrow: 1,
                    maxHeight: 400,
                }}
            >
                <Box>
                    <TextField
                        label={t("sign_in.email", { ns: Namespace.FORMS })}
                        value={email}
                        margin="normal"
                        variant="outlined"
                        type="email"
                        InputProps={{
                            onChange: (event) => handleEmailChanged(event.target.value),
                        }}
                        disabled={userLoading}
                        error={emailError !== null}
                        helperText={emailError}
                        sx={{
                            maxWidth: "sm",
                            mb: 4,
                        }}
                    />

                    <Box
                        textAlign="center"
                    >
                        <ActionButton
                            color="primary"
                            disabled={!email}
                            loading={userLoading}
                            onClick={handleReceiveLinkPressed}
                            type="submit"
                        >
                            {t("receive signin link", { ns: Namespace.ACTIONS })}
                        </ActionButton>
                    </Box>
                </Box>
            </form>
        </Box>
    )
}

export default LoginPage;