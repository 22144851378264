import { Box } from "@mui/material";
import { ReactNode } from "react";

type CenteredBoxProps = {
    children: ReactNode;
};

/**
 * Utility component to center content into a Flexbox
 */
const CenteredBox: React.FC<CenteredBoxProps> = ({ children }) => (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexGrow: '1' }}>
        {children}
    </Box>
);

export default CenteredBox;