import { Timestamp } from "firebase/firestore";
import { AddressComponents } from './Address';
import { NewUserData } from "./User";

export enum MissionStatus {
    PENDING = 'PENDING',
    IN_PROGRESS = 'IN_PROGRESS',
    COMPLETED = 'COMPLETED',
    CANCELLED = 'CANCELLED'
}

export type MissionsData = {
    ID: string;
    name: string;
    deadline: string | undefined;
    createdAt?: string;
    addresses?: AddressWithErrors[];
    assigned?: NewUserData[];
    mapImageURL? :string;
    status?: MissionStatus;
}

export type MissionDbData = {
    ID: string;
    name: string;
    deadline: Timestamp | undefined;
    createdAt?: Timestamp;
    addresses: AddressWithErrors[];
    assigned: NewUserData[];
    mapImageURL? :string;
    status?: MissionStatus;
}

export type AddressWithErrors = {
    address: AddressComponents;
    addressKey: string;
    errors: Record<string, number>;
    errorsCount: number;
    lat: number;
    lng: number;
    batchesCount?: number;
    visited: boolean;
}
