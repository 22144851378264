import { Box, useTheme } from "@mui/material";
import { ResponsiveBar } from "@nivo/bar";
import ErrorBarsLayer from "./ErrorBarsLayer";
import { ResponsivePie } from "@nivo/pie";
import { PIE_CHART_THEME, useSpacing } from "../../../constants/theme";
import { TrashType } from "../../../constants/trash";
import TrashTypeTooltip from "../TrashTypeTooltip";
import { useTranslation } from "react-i18next";
import { Namespace } from "../../../locales/translations";
import { MostFrequentErrorsBarChartProps } from "./MostFrequentErrorsChart";

export enum ChartType {
    PIE = "pie",
    BARS = "bars",
}

type ChartProps = Pick<MostFrequentErrorsBarChartProps, "height" | "pieData" | "data"> & {
    active: ChartType;
}

const MARGIN_HORIZONTAL = 2;


export default function MostFrequentErrorsPieOrBarChart({ active, height, data, pieData }: ChartProps) {
    const theme = useTheme();

    const heightPixels = Number(height(theme).slice(0, -2));
    const chartBarsHeight = useSpacing(data.length * 4);
    
    const { t } = useTranslation([Namespace.WASTES]);

    if (active === ChartType.PIE) {
        return (
            <ResponsivePie
                data={pieData}
                theme={PIE_CHART_THEME}
                valueFormat={(value) => `${value}%`}
                margin={{ top: 40, right: 0, bottom: 40, left: 0 }}
                padAngle={2}
                innerRadius={0.4}
                cornerRadius={4}
                borderWidth={1}
                borderColor={{
                    from: "color",
                    modifiers: [
                        [
                            'darker',
                            0.2
                        ]
                    ]
                }}
                colors={{ datum: "data.color" }}
                arcLabelsTextColor={(d) => d.value > 5 ? "#ffffff" : "rgba(0,0,0,0.8)"}
                arcLinkLabelsStraightLength={8}
                arcLinkLabel={d => d.id === TrashType.OTHER ? t('other_small', { ns: Namespace.WASTES }) : `${d.label}`}
                startAngle={-20}
                tooltip={({ datum }) => (
                    <TrashTypeTooltip
                        trashType={datum.id as TrashType}
                        label={datum.data.label}
                        value={datum.formattedValue}
                    />
                )}
            />
        );
    }

    return ( // horizontal bar chart 
        <Box
            width="100%"
            height={Math.max(chartBarsHeight, heightPixels)}
            position="relative"
        >
            <ResponsiveBar
                data={data}
                keys={['count']}
                layout="horizontal"
                indexBy="trashType"
                margin={{ top: 0, right: MARGIN_HORIZONTAL, bottom: 0, left: MARGIN_HORIZONTAL }}
                colors={({ id, data }) => data.color}
                // valueFormat={value => `${value} m\u00b2`}
                axisTop={null}
                axisRight={null}
                axisBottom={null}
                axisLeft={null}
                enableLabel={false}
                layers={[
                    // custom layer for labels
                    ({ bars }) => (
                        <ErrorBarsLayer
                            bars={bars}
                            mx={MARGIN_HORIZONTAL}
                        />
                    )
                ]}
            />
        </Box>
    );
}