import { BarTooltipProps } from "@nivo/bar";
import { ErrorPoint } from "./ErrorsEvolutionBarChart";
import { Box, Card } from "@mui/material";
import { Namespace } from "../../../locales/translations";
import TrashColorSquare from "../TrashColorSquare";
import { TrashType } from "../../../constants/trash";
import { useTranslation } from "react-i18next";

export default function ErrorsEvolutionTooltip({ data, id, formattedValue }: BarTooltipProps<ErrorPoint>) {
    const { t } = useTranslation([Namespace.WASTES]);
    
    return (
        <Card
            sx={{
                background: "white",
                padding: "9px 12px",
                border: "1px solid #ccc",
                borderRadius: 2,
            }}
        >
            <Box>
                {t("date_full_day", { ns: Namespace.DATES, date: new Date(data.date) })}
            </Box>
            <Box py="3px" display="flex" alignItems="center">
                <Box component="span" display="inline-flex" mr={1} mb="1px">
                    <TrashColorSquare trashType={id as TrashType} />
                </Box>
                <strong>{t(id.toString(), { ns: Namespace.WASTES })}</strong>: {formattedValue}
            </Box>
        </Card>
    );
}