import { getAlgoliaResults } from '@algolia/autocomplete-js';
import '@algolia/autocomplete-theme-classic';
import { Box, Typography } from '@mui/material';
import algoliasearch from 'algoliasearch/lite';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import BatchMethods from '../../actions/batch_action';
import { PROJECT_ID } from '../../constants/config';
import { getBatchKey } from '../../helpers/strings';
import { Namespace } from '../../locales/translations';
import { AlgoliaObjectData } from '../../models/Address';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { AddressActions } from '../../store/reducers/address_reducer';
import { SortingMapActions } from '../../store/reducers/batches/map';
import { Autocomplete } from './Autocomplete';


interface QueryObject {
    query: string;
}

interface HighlightProps {
    hit: AlgoliaObjectData;
    attribute: keyof AlgoliaObjectData | string[];
    tagName?: string;
}

interface ItemComponents {
    Highlight: React.FC<HighlightProps>;
};

interface TemplateItemProps {
    item: AlgoliaObjectData;
    components: ItemComponents;
}

interface ProductItemProps {
    hit: AlgoliaObjectData;
    components: ItemComponents;
    onHitClick: (hit: AlgoliaObjectData) => void;
};

export const DisabledAutocompleteComponent = () => {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '10px', backgroundColor: '#f0f0f0', borderRadius: '4px' }}>
            <Typography variant="subtitle1" color="textSecondary">
                Autocomplete is disabled. Please associate with a partner to use this feature.
            </Typography>
        </Box>
    );
};


const SearchBar: React.FC = () => {
    const dispatch = useAppDispatch();
    const partnerID = useAppSelector((state) => (state.user.partnerID));
    const isAdmin = useAppSelector((state) => (state.user.isAdmin));
    const algoliaApiKey = useAppSelector((state) => (state.algolia.apiKey));
    const algoliaAppID = useAppSelector((state) => (state.algolia.appID));
    const algoliaIndexName = `saved-addresses-${PROJECT_ID}`;
    const [query, setQuery] = useState('');

    /**
     * handleHitClick triggers fetching of batch documents for a selected place.
     * @param {AlgoliaObjectData} hit - The selected option returned data from Algolia.
     */
    const handleHitClick = async (hit: AlgoliaObjectData) => {
        if (hit.providerIDs && partnerID) {
            dispatch(AddressActions.setSelectedPlace(hit));

            let addressKey: string;

            if (hit.providerIDs.length === 1) {
                addressKey = hit.providerIDs[0]; // Use the single provider ID
            } else {
                // If there are multiple provider IDs, use the address component
                addressKey = getBatchKey({ hereID: undefined, address: hit.address });
            }
    
            dispatch(SortingMapActions.selectAddressKey(addressKey));
            await dispatch(BatchMethods.fetchBatchDocs(hit.providerIDs, partnerID));
            setQuery(hit.label); // Update the query with the selected item's label
        }
    };
    const { t } = useTranslation([Namespace.ADDRESSES]);

    if (!algoliaApiKey || !algoliaAppID) {
        return <div>Loading...</div>; 
    }

    const searchClient = algoliasearch(
        algoliaAppID,
        algoliaApiKey
    );

    /**
     * ProductItem is the individual autocomplete suggestion/option.
     * @param hit - The selected hit from Algolia.
     * @param components - This controlls each options and highlights them when they match with query.
     * @function onHitClick - This fetch the batchs for selected address 
     */
    const ProductItem: React.FC<ProductItemProps> = ({ hit, components, onHitClick }) => {
        return (
            <div onClick={() => onHitClick(hit)} role='button' style={{zIndex: 10}}>
                <div className="aa-ItemTitle" style={{zIndex: 10}}>
                    <components.Highlight hit={hit} attribute="label"/>
                </div>
            </div>
        );
    }



    if (!partnerID && !isAdmin) {
        // Handle the scenario for users without a partnerID
        return <DisabledAutocompleteComponent />;
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', minWidth: '100%', padding: '10px', gap: '10px', minHeight: "100%" }}>
            <Autocomplete
                placeholder={t("search_by_address", { ns: Namespace.ADDRESSES }).toString()}
                getSources={({ query }: QueryObject) => [
                    {
                        sourceId: algoliaIndexName,
                        getItems() {
                            let filter = `partnerID:${partnerID}`;

                            //Remove the filter if the user is an admin
                            if (isAdmin) {
                                filter = "";
                            }

                            return getAlgoliaResults({
                                searchClient,
                                queries: [
                                    {
                                        indexName: algoliaIndexName,
                                        query,
                                        params: {
                                            filters: filter,
                                        },
                                    },
                                ],
                            });
                        },
                        templates: {
                            item({ item, components }: TemplateItemProps) {
                                return <ProductItem hit={item} components={components} onHitClick={handleHitClick} />;
                            },
                        },
                    },
                ]}
                query={query}
            />
        </Box>
    );
};

export default SearchBar;
