import { createSlice, createEntityAdapter, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { AlgoliaObjectData } from '../../models/Address';


type ExtendedAddressState = EntityState<AlgoliaObjectData> & {
    selectedPlace: AlgoliaObjectData | null;
    loading: boolean;
    error: string | null;
};

// Define the entity adapter
const addressAdapter = createEntityAdapter<AlgoliaObjectData>({
    selectId: (address) => address.label,
});

const addressSlice = createSlice({
    name: 'address',
    initialState: addressAdapter.getInitialState({
        selectedPlace: null,
        loading: false,
        error: null,
    }) as ExtendedAddressState,
    reducers: {
        addAddress: addressAdapter.addOne,
        addManyAddress: addressAdapter.addMany,
        updateAddress: addressAdapter.updateOne,
        removeAddress: addressAdapter.removeOne,
        removeAllAddress: addressAdapter.removeAll,
        setSelectedPlace: (state, { payload: selectedPlace }: PayloadAction<AlgoliaObjectData | null>) => {
            state.selectedPlace = selectedPlace;
        },
        startLoading: (state) => {
            state.loading = true;
        },
        stopLoading: (state) => {
            state.loading = false;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
        clearError: (state) => {
            state.error = null;
        },
    },
});

export const AddressActions = addressSlice.actions;

const AddressReducer = addressSlice.reducer;

export default AddressReducer;
