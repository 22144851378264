import { ThemeProvider } from '@emotion/react';
import { APIProvider } from '@vis.gl/react-google-maps';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import Pages from './components/Pages';
import GlobalSnackbar from './components/_include/GlobalSnackBar';
import theme from './constants/theme';
import store from './store/store';

const FIREBASE_CONFIG = require(`./firebase_config/${process.env.REACT_APP_FIREBASE_CONFIG}`);

function App() {
  return (
    <APIProvider apiKey={FIREBASE_CONFIG.apiKey} libraries={['geometry']}>
      <BrowserRouter>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <Pages />
            <GlobalSnackbar />
          </ThemeProvider>
        </Provider>
      </BrowserRouter>
    </APIProvider>
  );
}

export default App;
