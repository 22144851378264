import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { TrashType, WASTES_COLORS } from "../../constants/trash";
import { Namespace } from "../../locales/translations";

type SquareProps = {
    trashType: TrashType;
}

export default function TrashColorSquare({ trashType }: SquareProps) {
    const { t } = useTranslation([Namespace.WASTES]);

    return (
        <Box
            title={t(trashType, { ns: Namespace.WASTES })}
            component="span"
            display="inline-block"
            width={(theme) => theme.spacing(2)}
            height={(theme) => theme.spacing(2)}
            borderRadius={1}
            border={1}
            borderColor="#ffffff"
            ml={.5}
            sx={{
                bgcolor: WASTES_COLORS[trashType],
            }}
        />
    )
}