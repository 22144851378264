import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import Partner from '../../../models/Partner';

interface PartnerState {
    partner: Partner | null;
    loading: boolean;
    error: string | null;
}

const initialState: PartnerState = {
    partner: null,
    loading: false,
    error: null,
};

const partnerSlice = createSlice({
    name: 'partner',
    initialState,
    reducers: {
        setPartner: (state, action: PayloadAction<Partner>) => {
            state.partner = action.payload;
        },
        startLoading: (state) => {
            state.loading = true;
        },
        stopLoading: (state) => {
            state.loading = false;
        },
        setError: (state, action: PayloadAction<string>) => {
            state.error = action.payload;
        },
        clearError: (state) => {
            state.error = null;
        },
    },
});

export const PartnerActions = partnerSlice.actions;

export const selectPartner = (state: RootState) => state.partner.partner;
export const selectPartnerLoading = (state: RootState) => state.partner.loading;
export const selectPartnerError = (state: RootState) => state.partner.error;

const PartnerReducer = partnerSlice.reducer;

export default PartnerReducer;
