import { Box, Typography } from '@mui/material';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import BatchMethods from '../../../actions/batch_action';
import MissionsMethods from '../../../actions/missions';
import { AppRoutes } from '../../../constants/routes';
import { Namespace } from '../../../locales/translations';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { MissionMapActions } from '../../../store/map_reducer';
import { SelectedMissionActions } from '../../../store/reducers/missions/selected';
import SectionLoader from '../../_include/SectionLoader';
import MissionDetailContent from './MissionDetailContent';

type MissionDetailProps = {
};

const MissionDetail: React.FC<MissionDetailProps> = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { missionID } = useParams();
    const { t } = useTranslation([Namespace.ADDRESSES]);
    const partnerID = useAppSelector((state) => state.user.partnerID);
    const loading = useAppSelector(state => state.mission.selected.loading);
    const mission = useAppSelector(state => state.mission.selected.selectedMission);


    useEffect(() => {
        if (partnerID && missionID) {
            dispatch(MissionsMethods.retrieve(partnerID, missionID));
        }
    }, [partnerID, dispatch, missionID]);

    const handleAddressClick = useCallback((placeID: string) => {
        dispatch(MissionMapActions.selectAddressKey(placeID));
    }, [dispatch]);

    const handleCheckboxChange = useCallback(
        (addressKey: string) => {
            if (partnerID && mission?.addresses) {
                const updatedAddresses = mission.addresses.map((address) =>
                    address.addressKey === addressKey
                        ? { ...address, visited: !address.visited }
                        : address
                );

                dispatch(SelectedMissionActions.updateMissionAddresses(updatedAddresses));

                dispatch(MissionsMethods.updateMission(partnerID, mission.ID,  { addresses: updatedAddresses }));
            }
        },
        [mission, partnerID, dispatch]
    );

    const onAddressSelected = async (addressKey: string) => { //address key is the hereID
        if (addressKey && partnerID) {
            await dispatch(BatchMethods.fetchBatchDocs([addressKey], partnerID));
            navigate(AppRoutes.BATCH_DETAIL);
        }
    };

    if (loading) return <SectionLoader />;

    return (
        <>
            {mission ? (
                <MissionDetailContent
                    mission={mission}
                    handleCheckboxChange={handleCheckboxChange}
                    handleAddressClick={handleAddressClick}
                    onAddressSelected={onAddressSelected}
                    navigate={navigate}
                />
            ) : (
                <Box padding={2}>
                    <Typography variant="body2" color="text.secondary">
                        {t('mission not found', { ns: Namespace.SNACKS })}
                    </Typography>
                </Box>
            )}
        </>
    );
};

export default MissionDetail;
