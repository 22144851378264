import { fetchAPI } from './actions';
import { AppDispatch } from '../store/store';
import { stringifyUrl } from 'query-string';
import urls from '../constants/urls';
import { AlgoliaActions, AlgoliaSecrets } from '../store/reducers/algolia';



const getAlgoliaAPIKey = async (dispatch: AppDispatch) => {
    dispatch(AlgoliaActions.startLoading());

    const query = stringifyUrl({
        url: `${urls.API}/user/getsecret/algoliaAPIKey`
    });
    try {
        const response = await fetchAPI(query);
        if (!response.secret.ApiKey || !response.secret.AppID) {
            dispatch(AlgoliaActions.setError("Invalid API response"));
        }
        const apiKey = response.secret.ApiKey;
        const appID = response.secret.AppID
        // Create the secret object
        const secret: AlgoliaSecrets = {
            apiKey: apiKey,
            appID: appID,
            loading: false, // Set loading to false
            error: null, // Set error to null
        };
        // Dispatch the secret
        dispatch(AlgoliaActions.setSecret(secret));
        return secret;
    }
    catch (e) {
        const error = e as Error;
        dispatch(AlgoliaActions.setError(error.message)); // Dispatch an error action
        return null;
    }
};




const AlgoliaMethods = {
    getAlgoliaAPIKey
};

export default AlgoliaMethods;