import { ControlPosition, Map } from '@vis.gl/react-google-maps';
import * as geofire from 'geofire-common';
import { useCallback, useEffect, useState } from "react";
import BatchMethods from '../../actions/batch_action';
import { AddressStatsPointFeature, DEFAULT_ZOOM_LEVEL, GRENOBLE_COORDINATES, MapState, calculateRadiusFromBounds, getCurrentUserLocation, getMapOptions } from "../../helpers/geo";
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { SortingMapActions } from '../../store/reducers/batches/map';
import '../../style/AutocompleteStyles.css';
import { ClusteredTreeMarkers } from './ClusterMarker';
import { CustomRecenterControl } from './CustomReCenterBtn';
import SearchAreaButton from './SearchAreaButton';
import UserLocationMarker from './UserLocationMarker';

type SearchMapProps = {
    mapID: string;
    points: AddressStatsPointFeature[];
    showMapControls: boolean;
}

/**
 * The SearchMap component displays a Google Map with various markers and controls.
 * It initializes the map center to the user's location if available, or falls back to Grenoble coordinates.
 * 
 * @param {SearchMapProps} props - The properties for the SearchMap component.
 * @returns {JSX.Element} The rendered SearchMap component.
 */
export default function SearchMap({ mapID, points, showMapControls }: SearchMapProps) {
    const dispatch = useAppDispatch();

    const defaultCenter = useAppSelector(state => state.batch.map.defaultCenter);

    const partnerID = useAppSelector(state => state.user.partnerID)!;

    const [{ zoom, center }, setMapState] = useState<MapState>({
        zoom: DEFAULT_ZOOM_LEVEL,
        center: GRENOBLE_COORDINATES,
    });

    useEffect(() => {
        const initializeMapCenter = async () => {
            const location = await getCurrentUserLocation();
            setMapState({
                zoom: DEFAULT_ZOOM_LEVEL,
                center: location || GRENOBLE_COORDINATES
            });
        };
        initializeMapCenter();
    }, []);


    useEffect(() => {
        if (defaultCenter) {
            setMapState({
                zoom: DEFAULT_ZOOM_LEVEL,
                center: defaultCenter,
            });
        }
    }, [defaultCenter]);

    /**
     * Handles the viewport change by calculating the geohash bounds and showing the "Search this area" button.
     * 
     * @param {google.maps.LatLngBoundsLiteral} bounds - The new bounds of the map viewport.
     */
    const handleViewportChange = (bounds: google.maps.LatLngBoundsLiteral) => {
        const boundsObj = new google.maps.LatLngBounds(
            new google.maps.LatLng(bounds.south, bounds.west),
            new google.maps.LatLng(bounds.north, bounds.east)
        );
        const center = boundsObj.getCenter();
        const radius = calculateRadiusFromBounds(boundsObj);
        const geohashBounds = geofire.geohashQueryBounds([center.lat(), center.lng()], radius);

        // Set state to show the "Search this area" button
        dispatch(SortingMapActions.toggleShowSearchAreaButton(true));

        // Save geohashBounds to use when the button is clicked
        sessionStorage.setItem('geohashBounds', JSON.stringify(geohashBounds));
    };


    /**
     * Handles marker clicks by fetching batch documents for the selected provider IDs and updating the map center.
     * 
     * @param {google.maps.LatLngLiteral} position - The position of the clicked marker.
     * @param {string[]} providerIDs - The provider IDs associated with the clicked marker.
     */
    const handleMarkerClick = useCallback(async (addressKey: string, position?: google.maps.LatLngLiteral, providerIDs?: string[]) => {
        dispatch(SortingMapActions.selectAddressKey(addressKey));
        if (providerIDs) await dispatch(BatchMethods.fetchBatchDocs(providerIDs, partnerID));
        if (position) setMapState({ center: position, zoom: DEFAULT_ZOOM_LEVEL, });
    }, [dispatch, partnerID]);

    /**
     * Recenters the map to the user's current GPS location.
     */
    const userGpsRecenter = async () => {
        const location = await getCurrentUserLocation();
        if (location) {
            setMapState({
                zoom: DEFAULT_ZOOM_LEVEL,
                center: location,
            });
        }
    };

    return (
        <Map
            mapId={mapID}
            center={center}
            zoom={zoom}
            onCameraChanged={(e) => {
                setMapState(e.detail);
            }}
            onBoundsChanged={(e) => {
                handleViewportChange(e.detail.bounds);
            }}
            {...getMapOptions(showMapControls)}
        >
            <ClusteredTreeMarkers points={points} onMarkerClick={handleMarkerClick} />
            <UserLocationMarker />
            <CustomRecenterControl
                controlPosition={ControlPosition.INLINE_END_BLOCK_END}
                onRecenter={userGpsRecenter}
            />

            <SearchAreaButton />
        </Map>
    );
}
