import { getDownloadURL, getStorage, ref } from "firebase/storage";


export enum StorageType {
    BATCHES = "batches",
    PARTNERS = "partners",
    MISSIONS = "missions"
};

export function getStorageURL(storage: StorageType) {
    return `gs://${process.env.REACT_APP_PROJECT_ID}-${storage}`;
}

export async function getPictureURL(imageURL: string, storageType: StorageType) {
    return await getFileURL(`${getStorageURL(storageType)}/${imageURL}`);
}

export async function getFileURL(filepath: string) {
    const storage = getStorage();
    const gsReference = ref(storage, filepath);

    return getDownloadURL(gsReference)
        .then((url) => {
            return url;
        })
        .catch((error) => {
            console.error(error);
            return null;
        });
}