import EqualizerIcon from '@mui/icons-material/Equalizer';
import PieChartIcon from '@mui/icons-material/PieChart';
import { Box, BoxProps, Grid, IconButton, Theme } from '@mui/material';
import { brown, green, orange, red, yellow } from "@mui/material/colors";
import { useState } from "react";
import { useTranslation } from 'react-i18next';
import { Namespace } from "../../../locales/translations";
import { useAppSelector } from '../../../store/hooks';
import SectionLoader from "../../_include/SectionLoader";
import ChartTitle from "../ChartTitle";
import MostFrequentErrorsPieOrBarChart, { ChartType } from './MostFrequentErrorsPieOrBarChart';
import { WasteSlice } from './MostFrequentErrorsWrapper';


export type MostFrequentErrorsBarChartProps = Pick<BoxProps, "px"> & {
    height: (theme: Theme) => string;
    data: ErrorPoint[];
    pieData: WasteSlice[];
    icons: boolean;
}

export enum ERROR_CATEGORY {
    WORST,
    BAD,
    MEDIUM,
    GOOD,
};

export const BAR_COLORS = {
    [ERROR_CATEGORY.WORST]: red[400],
    [ERROR_CATEGORY.BAD]: orange[500],
    [ERROR_CATEGORY.MEDIUM]: yellow[400],
    [ERROR_CATEGORY.GOOD]: green[400],
};

export const TEXT_COLORS = {
    [ERROR_CATEGORY.WORST]: "#ffffff",
    [ERROR_CATEGORY.BAD]: "#ffffff",
    [ERROR_CATEGORY.MEDIUM]: brown[400],
    [ERROR_CATEGORY.GOOD]: green[600],
};

export type ErrorPoint = {
    trashType: string;
    count: number;
    color: string;
    textColor: string;
};


function MostFrequentErrorsChart({ data, pieData, height, icons }: MostFrequentErrorsBarChartProps) {

    const [activeChart, setActiveChart] = useState<ChartType>(ChartType.BARS);

    const handleChartButtonClick = (chartType: ChartType) => {
        setActiveChart(chartType);
    }

    const { t } = useTranslation([Namespace.CHARTS]);

    const loading = useAppSelector(state => state.batch.list.loading || state.algolia.loading);

    return (
        <Box
            justifyContent="center"
        >
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <ChartTitle>
                    {t("most_frequent_errors.title", { ns: Namespace.CHARTS })}
                </ChartTitle>

                {icons && (
                    <Grid container direction="row" justifyContent="end" alignItems="end" item sm={4} md={2} >
                        <IconButton color={activeChart === ChartType.PIE ? "secondary" : "default"}  >
                            <PieChartIcon onClick={() => handleChartButtonClick(ChartType.PIE)} />
                        </IconButton>
                        <IconButton color={activeChart === ChartType.BARS ? "secondary" : "default"} >
                            <EqualizerIcon onClick={() => handleChartButtonClick(ChartType.BARS)} />
                        </IconButton>
                    </Grid>
                )}
            </Grid>

            <Box
                height={height}
                position="relative"
                sx={{
                    ...(activeChart === ChartType.BARS && { overflow: "scroll" })
                }}
            >
                {loading ? (
                    <SectionLoader />
                ) : (
                    <MostFrequentErrorsPieOrBarChart
                        active={activeChart}
                        height={height}
                        data={data}
                        pieData={pieData}
                    />
                )}
            </Box>
        </Box >
    );
}

export default MostFrequentErrorsChart;