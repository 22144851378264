import { List } from '@mui/material';
import { useAppSelector } from '../../store/hooks';
import { range } from 'lodash';
import MissionAddressItem from './MissionAddressItem';

type MissionAddressListProps = {
    onCheckboxChange: (addressKey: string) => void;
    onAddressClick: (addressKey: string) => void;
    onAddressDetailClick: (addressKey: string) => void;
};

const MissionAddressList: React.FC<MissionAddressListProps> = ({
    onCheckboxChange,
    onAddressClick,
    onAddressDetailClick
}) => {
    const addressesCount = useAppSelector(state => state.mission.selected.selectedMission?.addresses?.length ?? 0);

    return (
        <List>
            {range(addressesCount).map((index) => (
                <MissionAddressItem 
                    key={index} 
                    index={index} 
                    onCheckboxChange={onCheckboxChange} 
                    onAddressClick={onAddressClick} 
                    onAddressDetailClick={onAddressDetailClick} 
                    />
            ))}
        </List>
    );
};

export default MissionAddressList;
