import SearchIcon from '@mui/icons-material/Search';
import { BottomNavigation, BottomNavigationAction, Paper } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../constants/routes';
import { Z_INDEX_BOTTOM_NAV_BAR } from '../../constants/theme';
import { Namespace } from '../../locales/translations';
import { Assignment, Flag, FlagCircleSharp, FlagOutlined, OutlinedFlagOutlined, QrCodeScanner } from '@mui/icons-material';

function BottomNavigationComponent() {
    const navigate = useNavigate();
    const [value, setValue] = useState(AppRoutes.HOME);
    const { t } = useTranslation([Namespace.TITLES]);


    return (
        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: Z_INDEX_BOTTOM_NAV_BAR }} elevation={3}>
            <BottomNavigation
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                    navigate(newValue);
                }}
                showLabels
            >
                <BottomNavigationAction label={t("scan_rfid", {ns: Namespace.TITLES})} value="/scan-rfid" icon={<QrCodeScanner />} />
                <BottomNavigationAction label={t("search", {ns: Namespace.TITLES})} value="/home" icon={<SearchIcon />} />
                <BottomNavigationAction label={t("missions", {ns: Namespace.TITLES})} value="/missions" icon={<OutlinedFlagOutlined />} />
            </BottomNavigation>
        </Paper>
    );
}

export default BottomNavigationComponent;
