import { useAppSelector } from "../../store/hooks";
import { selectBatchIds } from "../../store/reducers/batches/batch_reducer";
import { selectFilteredBatchIds } from "../../store/reducers/batches/filtered_batches";
import { selectAllRfidBatches, selectRfidBatchIds } from "../../store/reducers/batches/rfid_batch_reducer";
import BatchCard from "./BatchCard";

type BatchCardListProps = {
}

const BatchCardList: React.FC<BatchCardListProps> = () => {
    const showRfid = useAppSelector(state => state.batch.rfid.showRfidsBatches);
    const showFilterData = useAppSelector(state => state.batch.filter.showFilter);
    const filteredBatchIds = useAppSelector(selectFilteredBatchIds); 
    const batchIds = useAppSelector(showRfid ? selectRfidBatchIds : selectBatchIds );
    const batchIdsToRender = showFilterData ? filteredBatchIds : batchIds;

    return (
        <>
            {batchIdsToRender.map((batchID, index) => (
                <BatchCard key={batchID} batchID={batchID} index={index} />
            ))}
        </>
    );
};

export default BatchCardList;
