import { Box, CircularProgress, Theme, darken, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { CSSProperties, MouseEventHandler, ReactNode } from 'react';
import IconActionButton from './IconActionButton';
import LabelActionButton from './LabelActionButton';

const GradientButtonSx: SxProps<Theme> = {
    color: "white.main",
    fontWeight: "bold",
    backgroundColor: (theme) => theme.palette.primary.main,
    backgroundImage: (theme) => `linear-gradient(135deg, ${theme.palette.secondary.main} 0%, ${theme.palette.primary.main} 50%)`,
    backgroundSize: `calc(100% + 100px)`,
    transition: `background-position .5s`,
    "&:hover": {
        backgroundPosition: "100%",
    },
};

export type OnClickAction = {
    type?: "button" | "submit" | "reset";
    onClick: MouseEventHandler<HTMLButtonElement> | undefined;
};

export type LabelButtonProps = {
    component: "label";
};

type LinkAction = {
    href: string;
};

type DownloadAction = {
    download: string;
};

type ButtonActions = OnClickAction | LinkAction | DownloadAction | LabelButtonProps;

export type ActionButtonProps = {
    id?: string;
    children?: ReactNode;
    color?: "white" | "primary" | "secondary" | "gradient" | "success" | "error" | "warning";
    disabled?: boolean;
    loading?: boolean;
    type?: "button" | "submit" | "reset";
    style?: CSSProperties;
    fullWidth?: boolean;
    startIcon?: ReactNode;
    endIcon?: ReactNode;
    icon?: ReactNode;
    size?: "normal" | "compact";
} & Partial<ButtonActions>;

const getTransitionString = (cssProp: string) => `${cssProp} 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`;

const isPaletteColor = (color: string | undefined, theme: Theme): color is keyof Theme['palette'] => {
    return color !== undefined && color in theme.palette;
};

const getButtonColor = (
    color: "white" | "primary" | "secondary" | "gradient" | "success" | "error" | "warning" | undefined
) => {
    switch (color) {
        case "gradient":
        case undefined:
            return undefined;
        case "white":
            return "inherit";
        default:
            return color;
    }
};

const getButtonStyle = (
    color: "white" | "primary" | "secondary" | "gradient" | "success" | "error" | "warning" | undefined,
    buttonDisabled: boolean,
    theme: Theme
): SxProps<Theme> => {
    if (color === "gradient" && !buttonDisabled) {
        return { ...GradientButtonSx };
    }

    if (color !== undefined) {
        let shadowColor: string | undefined;
        let hoverBgColor: string | undefined;

        if (color === "white") {
            shadowColor = "rgb(150,150,150)";
            hoverBgColor = "#ccc";
        } else if (isPaletteColor(color, theme)) {
            shadowColor = darken(theme.palette[color].main, 0.3);
            hoverBgColor = darken(theme.palette[color].main, 0.1);
        }

        return {
            boxShadow: `0px 4px ${shadowColor}`,
            "&:hover, &:active": {
                boxShadow: `0px 4px ${shadowColor}`,
                bgcolor: hoverBgColor,
            },
            "&:active": {
                boxShadow: `0px 0px ${shadowColor}`,
                mt: "4px",
                mb: "-4px",
            },
            color: color === "white" ? "#333" : "#ffffff",
            transition: `${getTransitionString("background-color")}, ${getTransitionString("box-shadow")}, ${getTransitionString("border-color")}, ${getTransitionString("color")}, ${getTransitionString("margin")}`,
            ...(color === "white" && { bgcolor: "#eee" }),
        };
    }

    return {};
};

const ActionButton = (props: ActionButtonProps) => {
    const { id, children, color, disabled, loading, style, fullWidth, startIcon, endIcon, icon, type, size, ...action } = props;

    const buttonDisabled = !!(disabled || loading);
    const theme = useTheme();
    const buttonStyle = getButtonStyle(color, buttonDisabled, theme);

    const handleDownload = () => {
        if ("download" in action && action.download) {
            const link = document.createElement("a");
            const downloadURIElements = action.download.split("/");
            link.download = downloadURIElements[downloadURIElements.length - 1];
            link.href = `./${action.download}`;
            link.click();
        }
    };

    const onClickHandler: MouseEventHandler<HTMLButtonElement> | undefined = 
        "onClick" in action ? action.onClick : "download" in action ? handleDownload : undefined;
    
    const href = "href" in action ? action.href : undefined;

    return (
        <Box
            style={{
                position: "relative",
                textAlign: "center",
                ...style,
                ...(fullWidth && { width: "100%" })
            }}
        >
            {icon ? (
                <IconActionButton
                    id={id}
                    disabled={buttonDisabled}
                    type={type}
                    onClick={onClickHandler}
                    buttonStyle={buttonStyle}
                    color={getButtonColor(color)}
                    size={size}
                    {...action}
                >
                    {icon}
                </IconActionButton>
            ) : (
                <LabelActionButton
                    id={id}
                    disabled={buttonDisabled}
                    type={type}
                    onClick={onClickHandler}
                    href={href}
                    startIcon={startIcon}
                    endIcon={endIcon}
                    fullWidth={fullWidth}
                    buttonStyle={buttonStyle}
                    color={getButtonColor(color)}
                    size={size}
                    {...action}
                >
                    {children}
                </LabelActionButton>
            )}

            {loading && (
                <CircularProgress
                    size={24}
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: -1.5,
                        marginLeft: -1.5,
                    }}
                />
            )}
        </Box>
    );
};

export default ActionButton;
