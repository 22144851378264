import { EntityState, PayloadAction, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { Batch } from '../../../models/Batches';
import { RootState } from '../../store';

// Create an entity adapter for batches
const rfid_batchesAdapter = createEntityAdapter<Batch>({
    selectId: (batch) => batch.timestamp,
    sortComparer: (a, b) => b.timestamp - a.timestamp, // sort batches by timestamp desc
});

// Define the extended batch state type
type ExtendedBatchState = EntityState<Batch> & {
    loading: boolean;
    error: string | null;
    showRfidsBatches: boolean;
    rfidValue: string | null;
    screenshot: string | null;
    scanning: boolean;
};

// Define the initial state with the explicit type
const initialState: ExtendedBatchState = {
    ...rfid_batchesAdapter.getInitialState(),
    loading: false,
    error: null,
    showRfidsBatches: false,
    rfidValue: null,
    screenshot: null,
    scanning: false,
};

// Create the slice with the initial state and reducers
const rfid_batchesSlice = createSlice({
    name: 'rfids_batches',
    initialState,
    reducers: {
        addBatch: rfid_batchesAdapter.addOne,
        addBatches: rfid_batchesAdapter.addMany,
        updateBatch: rfid_batchesAdapter.updateOne,
        removeBatch: rfid_batchesAdapter.removeOne,
        removeAllBatches: rfid_batchesAdapter.removeAll,
        clearRfid: (state) => {
            state.rfidValue = null;
            state.screenshot = null;
            state.scanning = false;
            state.error = null;
            rfid_batchesAdapter.removeAll(state);
        },
        startLoading: (state) => {
            state.loading = true;
        },
        stopLoading: (state) => {
            state.loading = false;
        },
        setError: (state, action: PayloadAction<string>) => {
            state.error = action.payload;
        },
        clearError: (state) => {
            state.error = null;
        },
        showRfidBatches: (state, { payload: show }: PayloadAction<boolean>) => {
            state.showRfidsBatches = show;
        },
        setStartScanning: (state, { payload: scanning }: PayloadAction<boolean>) => {
            state.scanning = scanning;
        },
        setRfidValue: (state, { payload: rfid }: PayloadAction<string>) => {
            state.rfidValue = rfid;
        },
        setScreenshot: (state, { payload: screenshot }: PayloadAction<string>) => {
            state.screenshot = screenshot;
        },
        setScannerResult: (state, { payload: { screenshot, rfidValue } }: PayloadAction<{ screenshot: string, rfidValue: string }>) => {
            state.screenshot = screenshot;
            state.rfidValue = rfidValue;
        },
    },
});

// Export actions for use in components or thunks
export const RfidsBatchesActions = rfid_batchesSlice.actions;

// Export selectors
export const {
    selectAll: selectAllRfidBatches,
    selectById: selectRfidBatchById,
    selectIds: selectRfidBatchIds,
} = rfid_batchesAdapter.getSelectors((state: RootState) => state.batch.rfid);

// Export the reducer to be included in the root reducer
const RfidsBatchesReducer = rfid_batchesSlice.reducer;
export default RfidsBatchesReducer;
