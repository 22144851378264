import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Namespace } from '../../locales/translations';
import { PermissionStatus } from './ScanRfid';

interface ScanPromptProps {
    onStart: () => void;
    permissionStatus: PermissionStatus;
}

const ScanPrompt: React.FC<ScanPromptProps> = ({ onStart, permissionStatus }) => {
    const { t } = useTranslation([Namespace.RFID]);
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'flex-start',
                paddingTop: '40px',
            }}
        >
            <Typography variant="h4" sx={{ color: '#fff', textAlign: 'center', marginBottom: '50px', px: '50px' }}>
                {t(`find_images`, { ns: Namespace.RFID })}
            </Typography>

            <Box
                sx={{
                    width: '283px',
                    height: '130px',
                    backgroundColor: '#fff',
                    borderRadius: '12px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: '50px',
                }}
            >
                <img src="/images/barcode.png" alt="Barcode" style={{ width: '109px' }} />
            </Box>

            <Typography variant="body1" sx={{ color: '#fff', textAlign: 'center', paddingBottom: '32px', px: '50px' }}>
                {t('scan_rfid', { ns: Namespace.RFID })}
            </Typography>
            {permissionStatus !== "granted" && (
                <Typography variant="body1" sx={{ color: '#fff', textAlign: 'center', paddingBottom: '32px', px: '50px' }}>
                    {permissionStatus === "prompt" ? t('camera_access', { ns: Namespace.RFID }) : t('camera_permission_denied', { ns: Namespace.RFID })}
                </Typography>
            )}
            <Box paddingBottom="100px">
                <Button variant="contained" disabled={permissionStatus === "denied"} onClick={onStart} sx={{
                    width: '253px', height: '48px', backgroundColor: '#336699', '&:hover': {
                        backgroundColor: '#336699', // Match hover color to default background
                    },
                }}>
                    {permissionStatus !== "denied" ? t('scan_button', { ns: Namespace.RFID }) : t('permission_button', { ns: Namespace.RFID })}
                </Button>
            </Box>
        </Box>
    );
};

export default ScanPrompt;