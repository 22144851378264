import { ControlPosition, Map } from '@vis.gl/react-google-maps';
import { useCallback, useEffect, useState } from "react";
import { DEFAULT_ZOOM_LEVEL, GRENOBLE_COORDINATES, MapState, getBoundsZoomLevel, getCurrentUserLocation, getMapOptions } from "../../helpers/geo";
import { AddressWithErrors } from "../../models/Missions";
import AddressMarker from '../Map/AddressMarker';
import { getDangerLevel } from '../../actions/batch_action';
import { DANGER_COLORS } from '../../helpers/draw';
import UserLocationMarker from '../Map/UserLocationMarker';
import { CustomRecenterControl } from '../Map/CustomReCenterBtn';

type MissionMapProps = {
    mapID: string;
    width: number;
    height: number;
    points: AddressWithErrors[];
    showMapControls: boolean;
    onMarkerClick: (addressKey: string) => void;
}

export default function MissionMap({ mapID, width, height, points, showMapControls, onMarkerClick }: MissionMapProps) {
    const [{ zoom, center }, setMapState] = useState<MapState>({
        zoom: 15,
        center: GRENOBLE_COORDINATES,
    });

    const defaultCenter = GRENOBLE_COORDINATES;

    useEffect(() => {
        if (points.length > 0) {
            let minLat = 999, minLng = 999, maxLat = -999, maxLng = -999;
            for (let { lat, lng } of points) {
                if (lat < minLat) minLat = lat;
                if (lat > maxLat) maxLat = lat;
                if (lng < minLng) minLng = lng;
                if (lng > maxLng) maxLng = lng;
            }

            const zoom = getBoundsZoomLevel({
                north: maxLat,
                east: maxLng,
                south: minLat,
                west: minLng,
            }, { width, height });

            const center = { lat: (minLat + maxLat) / 2, lng: (minLng + maxLng) / 2 };

            setMapState({
                zoom,
                center,
            });
        } else {
            setMapState({
                zoom,
                center: defaultCenter,
            });
        }
    }, [points, defaultCenter, width, height]);

    const handleMarkerClick = useCallback((addressKey : string) => {
        onMarkerClick(addressKey);
    }, []);

    /**
     * Recenters the map to the user's current GPS location.
     */
    const userGpsRecenter = async () => {
        const location = await getCurrentUserLocation();
        if (location) {
            setMapState({
                zoom: DEFAULT_ZOOM_LEVEL,
                center: location,
            });
        }
    };


    return (
        <Map
            mapId={mapID}
            center={center}
            zoom={zoom}
            onCameraChanged={(e) => setMapState(e.detail)}
            {...getMapOptions(showMapControls)}
        >
            {points.map(point => (
                <AddressMarker
                    key={point.addressKey}
                    position={point}
                    addressKey={point.addressKey}
                    onClick={handleMarkerClick}
                    color={DANGER_COLORS[getDangerLevel(point.errorsCount)]}
                />
            ))}
             <CustomRecenterControl
                controlPosition={ControlPosition.INLINE_END_BLOCK_END}
                onRecenter={userGpsRecenter}
            />
             <UserLocationMarker />
        </Map>
    );
}
