import { ParsedToken } from "firebase/auth";
import { Locale } from "../locales/translations";

export enum UserRoles {
    ADMIN = "admin",
    PARTNER = "partner",
    AMBASSADOR = "ambassador"
};

export type FirebaseUserClaims = ParsedToken & UserClaims;

export type UserClaims = {
    locale: Locale;
    role: UserRoles;
    partners: {
        [partnerID : string]: UserRoles
    }
}

export type AmbassadorData = {
    ID?: string;
    displayName: string;
    email: string;
    creationTime?: string;
    role: string;
    partnerID?: string;
}
export type NewUserData = {
    name: string | null;
    role: UserRoles;
    partnerID: string | null;
    email: string | null;
    locale: Locale;
}
