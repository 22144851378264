import { Card, CardContent, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TrashType } from '../../constants/trash';
import { formatAddress } from '../../helpers/geo';
import { getAggregatedErrors, getErrors } from '../../helpers/trash';
import { Namespace } from '../../locales/translations';
import { useAppSelector, useSortingRules } from '../../store/hooks';
import { selectAllBatches } from '../../store/reducers/batches/batch_reducer';
import { selectAllRfidBatches } from '../../store/reducers/batches/rfid_batch_reducer';

interface KeyNumbersCardProps {
    onSeeMoreClick: () => void;
}

const KeyNumbersCard: React.FC<KeyNumbersCardProps> = ({ onSeeMoreClick }) => {
    const { errorsClasses } = useSortingRules();
    const showRfid = useAppSelector(state => state.batch.rfid.showRfidsBatches);

    const batches = useAppSelector(state => showRfid ? selectAllRfidBatches(state) : selectAllBatches(state));
    const rfidValue = useAppSelector(state => state.batch.rfid.rfidValue);
    const { t } = useTranslation([Namespace.STATS, Namespace.COMMONS, Namespace.RFID]);

    const aggregatedErrors = useMemo(() => getAggregatedErrors(errorsClasses, batches), [errorsClasses, batches]);
    const mostCommonMistake = Object.keys(aggregatedErrors).reduce((a, b) => aggregatedErrors[a as TrashType]! > aggregatedErrors[b as TrashType]! ? a : b, "") as TrashType;
    const totalBatches = batches.length;

    const batchesWithErrors = batches.filter(batch => {
        const errors = getErrors(errorsClasses, batch.results);
        const errorsCount = Object.values(errors).reduce((acc, count) => acc + count, 0);
        return errorsCount > 0;
    }).length;

    const averageQuality = totalBatches > 0
        ? ((1 - (batchesWithErrors / totalBatches)) * 100).toFixed(0)
        : "100%";

    const address = showRfid
        ? `${t("bin_identifier", { ns: Namespace.RFID })}: ${rfidValue}`
        : (batches.length > 0 ? formatAddress(batches[0].address!) : t("no_address_available", { ns: Namespace.STATS }));     // Check if batches array is non-empty before accessing batches[0].address

    return (
        <Card elevation={0}>
            <CardContent style={{ padding: 0 }}>
                <Typography color='#999' fontSize='16px' fontStyle='italic' fontWeight='700' lineHeight='150%'>{address}</Typography>
                <Typography variant="h4">{t("key_numbers", { ns: Namespace.STATS })}</Typography>
                <Typography variant="body1" paddingBottom={1}>
                    {t("most_common_mistake", { ns: Namespace.STATS })}
                    <Typography component="span" variant="body1" fontWeight="bold">
                        {t(`${mostCommonMistake}`, { ns: Namespace.WASTES })}
                    </Typography>
                </Typography>
                <Typography variant="body1" paddingY={0.5}>
                    {t("average_quality", { ns: Namespace.STATS })}
                    <Typography component="span" variant="body1" fontWeight="bold">
                        {averageQuality}%
                    </Typography>
                </Typography>
                <Typography paddingTop={1} variant="h5" color="secondary" style={{ cursor: 'pointer' }} onClick={onSeeMoreClick}>
                    {t("see_more", { ns: Namespace.COMMONS })}
                </Typography>
            </CardContent>
        </Card>
    );
};

export default KeyNumbersCard;
