import Box from '@mui/material/Box';
import { Outlet } from 'react-router-dom';
import BottomNavigationComponent from '../BottomNavigation/BottomNavigationBar';

type LoggedInLayoutProps = {};

function LoggedInLayout(props: LoggedInLayoutProps) {
    return (
        <Box display={"flex"} minHeight={"100vh"} flexDirection={"column"} paddingBottom={8}>
            <Outlet />
            <BottomNavigationComponent />
        </Box>
    );
}

export default LoggedInLayout;