import { Box, useTheme } from '@mui/material';
import { ResponsiveBar } from "@nivo/bar";
import { DatumValue } from "@nivo/core";
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TrashType, WASTES_COLORS } from "../../../constants/trash";
import { getAggregatedErrorsByDate } from "../../../helpers/trash";
import { Namespace } from "../../../locales/translations";
import { useAppSelector, useSortingRules } from "../../../store/hooks";
import { selectAllBatches } from "../../../store/reducers/batches/batch_reducer";
import SectionLoader from "../../_include/SectionLoader";
import ChartTitle from '../ChartTitle';
import CustomLegend from "../CustomLegend";
import ErrorsEvolutionTooltip from "./ErrorsEvolutionTooltip";

type ColorID = `${TrashType}Color`;

type ColorsMap = Partial<{ [colorId in ColorID]: string }>;

export type ErrorPoint = {
    date: number;
    totalErrors: number;
} & Partial<Record<TrashType, number>> & ColorsMap;

const TICK_WIDTH = 120;

function ErrorsEvolutionBarChart() {
    const batches = useAppSelector(state => selectAllBatches(state));
    const loading = useAppSelector(state => state.batch.list.loading || state.algolia.loading);

    const { errorsClasses } = useSortingRules();

    const { t } = useTranslation([Namespace.CHARTS, Namespace.STATS, Namespace.WASTES]);

    const theme = useTheme();

    const data: ErrorPoint[] = useMemo(() => {
        let errorsEvolution: ErrorPoint[] = [];
        if (!batches) return errorsEvolution; // results not loaded yet

        const aggregatedByDate = getAggregatedErrorsByDate(errorsClasses, batches);

        Object.entries(aggregatedByDate).forEach(([date, errors]) => {
            let totalErrors = 0;
            let surfaces: { [errorType in TrashType]?: number } = {};
            let colors: ColorsMap = {};

            Object.entries(errors).forEach(([errorType, count]) => {
                totalErrors += count;
                const type = errorType as TrashType;
                surfaces[type] = count;
                colors[`${type}Color`] = WASTES_COLORS[type];
            });

            errorsEvolution.push({
                date: new Date(date).getTime(), // Convert date to timestamp
                totalErrors,
                ...surfaces,
                ...colors,
            });
        });

        return errorsEvolution.sort((a, b) => a.date - b.date);// sorting date by old first
    }, [batches]);

    // adapt bottom axis ticks to chart width to prevent overlap
    const wrapperRef = useRef<HTMLDivElement>();
    const wrapperWidth = wrapperRef.current?.offsetWidth;
    const [bottomTickValues, setBottomTickValues] = useState<DatumValue[]>([]);
    useEffect(() => {
        if (!wrapperWidth) setBottomTickValues([]);
        else {
            let xValues = data.map((d) => d.date);

            let gridWidth = Math.ceil(wrapperWidth / xValues.length);
            let tickDistance = Math.floor(TICK_WIDTH / gridWidth);

            setBottomTickValues(tickDistance === 0 ? xValues : xValues.filter((_, i) => i % tickDistance === 0));
        }
    }, [TICK_WIDTH, wrapperWidth, data]);

    // Filter keys based on the data
    const legendKeys = useMemo(() => {
        return errorsClasses.filter(key => data.some(d => d[key] !== undefined));
    }, [data]);

    return (
        <Box justifyContent="center" my={2}>
            <ChartTitle>
                {t("errors_count_evolution.title", { ns: Namespace.CHARTS })}
            </ChartTitle>
            <Box ref={wrapperRef} width="100%" height={theme => theme.spacing(50)} position="relative">
                {loading ? (
                    <SectionLoader />
                ) : (
                        <ResponsiveBar
                            data={data}
                            keys={errorsClasses}
                            indexBy="date"
                            margin={{ top: 10, right: 16, bottom: 80, left: 16 }}
                            colors={({ id, data }) => data[`${id.toString()}Color` as ColorID]!}
                            indexScale={{ type: 'band' }}
                            valueScale={{ type: 'linear' }}
                            axisTop={null}
                            axisRight={null}
                            axisBottom={{
                                tickValues: bottomTickValues,
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 45,
                                legend: t('errors_count_evolution.bottomAxis', { ns: Namespace.CHARTS }),
                                legendOffset: 70,
                                legendPosition: 'middle',
                                format: (tick) => t("date_day", { ns: Namespace.DATES, date: new Date(tick) }),
                            }}
                            axisLeft={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: t('errors_count_evolution.leftAxis', { ns: Namespace.CHARTS }),
                                legendOffset: -40,
                                legendPosition: 'middle'
                            }}
                            labelSkipWidth={28} // less than 28px wide or 1rem height: bar too small to display label
                            labelSkipHeight={theme.typography.fontSize}
                            labelTextColor="#FFFFFF"
                            tooltip={ErrorsEvolutionTooltip}
                        />
                )}
            </Box>
                <CustomLegend keys={legendKeys} />
        </Box>
    );
}

export default ErrorsEvolutionBarChart;
