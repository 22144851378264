import { CenterAndBounds, ErrorsByAddress } from "../constants/types";
import { AddressComponents } from "../models/Address";
import { PositionedBatch } from "../models/Batches";
import { Coords } from "../store/reducers/batches/map";

export type BatchMarkerColor = "error" | "warning" | "success";

export interface Coordinates {
    latitude: number;
    longitude: number;
}

export type BatchPoint = {
    batchID: string;
    placeID: string;
    color: BatchMarkerColor;
    position: google.maps.LatLngLiteral;
}


/** Levels of threat represented by a number of sorting errors */
export enum DangerLevel {
    /** 0 error */
    NONE = "0",
    /** 1-3 errors */
    LOW = "1",
    /** 4-6 errors */
    MEDIUM = "4",
    /** 7-10 errors */
    HIGH = "7",
    /** 11+ errors */
    VERY_HIGH = "11",
};

/**
 * Properties for a map point representing a single address,
 * as a "cluster" of the batches at this address over time
 */
export type AddressPointProperties = ErrorsByAddress & Pick<PositionedBatch, "address" | "hereID"> & {
    /** List of the IDs of the batches at this address */
    batchesIDs: string[];

    /** Number of batches at this address */
    batchesCount: number;

    /** Number of batches with at least 1 error at this address */
    batchesWithErrorsCount: number;

    /** Indicates how many errors were made at this address */
    dangerLevel: DangerLevel;
};


/**
 * GeoJSON feature representing a single point
 */
export type AddressStatsPointFeature = {
    type: "Feature";
    properties: AddressPointProperties,
    geometry: {
        type: "Point";
        coordinates: number[];
    };
}

type Viewport = {
    northeast: google.maps.LatLngLiteral;
    southwest: google.maps.LatLngLiteral;
};

export type MapGeometry = {
    location: google.maps.LatLngLiteral;
    viewport: Viewport;
}

export type SimpleAddress = {
    name: string;
    vicinity: string;
}

export type MapView = {
    north: number;
    east: number;
    south: number;
    west: number;
}

export type MapState = Pick<google.maps.MapOptions, "center" | "zoom">;

export const UNNAMED_ROAD = "Unnamed Road";

export const DEFAULT_ZOOM_LEVEL = 17;
export const MAX_ZOOM = 20;

export const GRENOBLE_COORDINATES = { lat: 45.19, lng: 5.72 };
export const PARIS_COORDINATES = { lat: 48.916753, lng: 2.527503 };

export const DEFAULT_BOUNDS = {
    north: 0,
    east: 0,
    south: 0,
    west: 0,
};

export const DEFAULT_CENTER_AND_BOUNDS: CenterAndBounds = {
    bounds: DEFAULT_BOUNDS,
    center: GRENOBLE_COORDINATES,
};

export function getBoundsZoomLevel(bounds: google.maps.LatLngBoundsLiteral, mapDim: { width: number, height: number }) {
    const WORLD_DIM = { height: 360, width: 360 };

    function latRad(lat: number) {
        const sin = Math.sin(lat * Math.PI / 180);
        const radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
        return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
    }

    function zoom(mapPx: number, worldPx: number, fraction: number) {
        return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
    }

    const { north, east, south, west } = bounds;

    const latFraction = (latRad(north) - latRad(south)) / Math.PI;

    const lngDiff = east - west;
    const lngFraction = ((lngDiff < 0) ? (lngDiff + 360) : lngDiff) / 360;

    const latZoom = zoom(mapDim.height, WORLD_DIM.height, latFraction);
    const lngZoom = zoom(mapDim.width, WORLD_DIM.width, lngFraction);

    return Math.min(latZoom, lngZoom, MAX_ZOOM);
}

/**
 * Get the default options for Google Maps in this app.
 */
export function getMapOptions(mapTypeControl: boolean = true): google.maps.MapOptions {
    return {
        maxZoom: MAX_ZOOM,
        mapTypeControl: mapTypeControl,
        mapTypeControlOptions: mapTypeControl ? {
            style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
            position: google.maps.ControlPosition.BOTTOM_CENTER,
            mapTypeIds: [
                google.maps.MapTypeId.ROADMAP,
                google.maps.MapTypeId.SATELLITE,
            ],
        } : undefined,
        clickableIcons: false,
        fullscreenControl: false,
        zoomControl: mapTypeControl,
        streetViewControl: mapTypeControl,
        scaleControl: mapTypeControl,
        rotateControl: mapTypeControl,
    }
}


export const viewportToBounds = (viewport: Viewport) => {
    const north = viewport.northeast.lat;
    const south = viewport.southwest.lat;
    const east = viewport.northeast.lng;
    const west = viewport.southwest.lng;

    return { north, south, east, west };
}

export function getCenterAndBounds(minLat: number, maxLat: number, minLng: number, maxLng: number): CenterAndBounds {
    if (maxLat >= minLat && maxLng >= minLng) { // at least one point
        const center: google.maps.LatLngLiteral = {
            lat: (maxLat + minLat) / 2,
            lng: (maxLng + minLng) / 2,
        };
        const bounds: google.maps.LatLngBoundsLiteral = {
            north: maxLat,
            east: maxLng,
            south: minLat,
            west: minLng,
        }

        return { center, bounds };
    }
    else { // no point, return default
        return DEFAULT_CENTER_AND_BOUNDS;
    }
}

export function getPointsBoundsAndCenter(points: google.maps.LatLngLiteral[]): CenterAndBounds {
    let minLat = 999;
    let maxLat = -999;
    let minLng = 999;
    let maxLng = -999;

    for (let point of points) {
        if (point.lat < minLat) minLat = point.lat;
        if (point.lat > maxLat) maxLat = point.lat;
        if (point.lng < minLng) minLng = point.lng;
        if (point.lng > maxLng) maxLng = point.lng;
    }

    return getCenterAndBounds(minLat, maxLat, minLng, maxLng);
}


/**
 * Check if two coordinates (object with lat and lng fields) are the same at a given level of precision passed as parameter
 */
export function areSameCoordinates(coord1: google.maps.LatLngLiteral, coord2: google.maps.LatLngLiteral, precision: number): boolean {
    const latPrecision = Math.pow(10, precision);
    const lngPrecision = Math.pow(10, precision);
    const roundedLat1 = Math.round(coord1.lat * latPrecision) / latPrecision;
    const roundedLng1 = Math.round(coord1.lng * lngPrecision) / lngPrecision;
    const roundedLat2 = Math.round(coord2.lat * latPrecision) / latPrecision;
    const roundedLng2 = Math.round(coord2.lng * lngPrecision) / lngPrecision;
    return roundedLat1 === roundedLat2 && roundedLng1 === roundedLng2;
}

/**
 * Extract and format the address components for display
 */
export function formatAddress(address: Pick<AddressComponents, "houseNumber" | "street" | "postalCode" | "city">): string {
    if (!address) return "";

    const { houseNumber, street, city, postalCode } = address;

    let houseNumberPrefix = houseNumber ? `${houseNumber} ` : ""; // house number and space before street name

    return `${houseNumberPrefix}${street}, ${postalCode} ${city}`;
}

/**
 * Extract the user location coordinates for display on map
 */
export const getCurrentUserLocation = (): Promise<Coords | null> => {
    return new Promise((resolve) => {
        if (!navigator.geolocation) {
            console.warn("Geolocation is not supported by this browser.");
            resolve(null);
            return;
        }

        navigator.geolocation.getCurrentPosition(
            (position) => {
                const newUserLocation = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                };
                resolve(newUserLocation);
            },
            (error) => {
                switch (error.code) {
                    case error.PERMISSION_DENIED:
                        console.warn("User denied the request for Geolocation.");
                        break;
                    case error.POSITION_UNAVAILABLE:
                        console.warn("Location information is unavailable.");
                        break;
                    case error.TIMEOUT:
                        console.warn("The request to get user location timed out.");
                        break;
                    default:
                        console.warn("An unknown error occurred.");
                        break;
                }
                resolve(null); // Fallback to null if any error occurs
            },
        );
    });
};

/**
 * Calculates the radius from the center of the bounds to the northeast corner.
 * 
 * @param {google.maps.LatLngBounds} bounds - The bounds of the map viewport.
 * @returns {number} The radius in meters.
 */
export const calculateRadiusFromBounds = (bounds: google.maps.LatLngBounds): number => {
    const center = bounds.getCenter();
    const ne = bounds.getNorthEast();
    const radius = google.maps.geometry.spherical.computeDistanceBetween(center, ne);
    return radius;
};