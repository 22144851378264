import React, { useRef, useCallback, useEffect } from 'react';
import Webcam from 'react-webcam';
import { Box, Typography } from '@mui/material';
import { BrowserMultiFormatReader, Result } from '@zxing/library';
import { ArrowUpwardRounded } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Namespace } from '../../locales/translations';
import { RfidsBatchesActions } from '../../store/reducers/batches/rfid_batch_reducer';
import { useAppDispatch } from '../../store/hooks';
import { showError } from '../../store/reducers/snacks';
import SvgMask from './SvgMask';

interface BarcodeScannerProps {
}

const BarcodeScanner: React.FC<BarcodeScannerProps> = ({ }) => {
    const webcamRef = useRef<Webcam>(null);
    const codeReaderRef = useRef(new BrowserMultiFormatReader());
    const { t } = useTranslation([Namespace.RFID]);
    const dispatch = useAppDispatch();

    // Callback to start scanning
    const startScan = useCallback(() => {
        if (webcamRef.current) {
            const video = webcamRef.current.video as HTMLVideoElement;

            // Start decoding from the video element
            codeReaderRef.current.decodeFromVideoElement(video)
                .then((result: Result) => {
                    const screenshot = webcamRef.current?.getScreenshot(); // Capture screenshot
                    if (screenshot) {
                        dispatch(RfidsBatchesActions.setScannerResult({
                            screenshot,
                            rfidValue: result.getText()
                        }));
                    }
                    dispatch(RfidsBatchesActions.setStartScanning(false)); // Set scanning to false
                })
                .catch((err: any) => {
                    console.error(err);
                    if (!['NotFoundException', 'IndexSizeError'].includes(err.name)) { // happens when leaving screen when video is streaming 
                        dispatch(showError(err.message));
                    }
                });
        }
    }, [dispatch]);

    // Start scanning when the component mounts
    useEffect(() => {
        startScan();

        // Cleanup the scanner when the component unmounts
        return () => {
            codeReaderRef.current.reset();
        };
    }, [startScan]);

    return (
        <Box sx={{ position: 'relative', width: '100%', height: '100%', }}>
            <Webcam
                ref={webcamRef}
                videoConstraints={{ facingMode: { ideal: 'environment' } }} // Rear camera on mobile
                style={{ width: '100%', height: '100vh', objectFit: 'cover', zIndex: -1 }}
                screenshotFormat="image/jpeg"
            />

           {/* Use the reusable SvgMask component */}
           <SvgMask />
           
            <Box
                sx={{
                    position: 'absolute',
                    bottom: '15%',
                    width: '100%',
                    textAlign: 'center',
                    px: '55px',
                    color: 'white',
                    zIndex: 2,
                }}
            >
                <ArrowUpwardRounded sx={{ fontSize: 64, color: '#ffff' }} />
                <Typography variant="h6" sx={{ fontWeight: 600, paddingTop: '36px', color: '#ffff' }}>
                    {t('place_barcode_instruction', { ns: Namespace.RFID })}
                </Typography>
            </Box>
        </Box>
    );
};

export default BarcodeScanner;
