import { DocumentData, QueryDocumentSnapshot, collection, getDocs, getFirestore, query, where } from 'firebase/firestore';
import { pick, sum } from 'lodash';
import { DbCollection } from '../constants/db';
import { CenterAndBounds } from '../constants/types';
import { AddressStatsPointFeature, DangerLevel, getCenterAndBounds } from '../helpers/geo';
import { getBatchKey } from '../helpers/strings';
import { getErrors, getSortingErrorClasses, sumResults } from '../helpers/trash';
import { Batch, BatchDbData, PositionedBatch } from '../models/Batches';
import { MissionMapActions } from '../store/map_reducer';
import { BatchesActions } from '../store/reducers/batches/batch_reducer';
import { setFilteredBatches } from '../store/reducers/batches/filtered_batches';
import { SortingMapActions } from '../store/reducers/batches/map';
import { AppDispatch } from '../store/store';
import { handleAPIError } from './actions';
import { TrashType } from '../constants/trash';
import { RfidsBatchesActions } from '../store/reducers/batches/rfid_batch_reducer';
import { useAppSelector } from '../store/hooks';

/** Default state in which user can see the entire image. */
export const DEFAULT_ZOOM = { zoom: 1, transform: { x: 0, y: 0 } };


/**
 * Build a Batch object from its Firestore document
 */
function fromDbDoc(dbDoc: QueryDocumentSnapshot<DocumentData>): Batch {
    const data: BatchDbData = dbDoc.data() as BatchDbData;

    return {
        ID: dbDoc.id,
        partnerID: dbDoc.ref.parent.parent!.id,
        ...data,
        timestamp: data.timestamp.toMillis(),
        loading: false,
        imageZoom: DEFAULT_ZOOM,
    };
}

/**
 * Fetches batch documents associated with a specific place ID from Firestore.
 * @param providerIDs - The address providerIDs to query for the bacthes at a place.
 * @param userPartnerID - The userPartnerID to query for batches from the associated partner.
 * @returns A thunk function for Redux to execute.
 */
export const fetchBatchDocs = (providerIDs: string[], userPartnerID: string) => async (dispatch: AppDispatch) => {
    dispatch(BatchesActions.startLoading());
    dispatch(BatchesActions.clearError());
    dispatch(BatchesActions.removeAllBatches());

    const errorsClasses = getSortingErrorClasses();

    try {
        const db = getFirestore();
        // Directly access the specific partner's batches collection
        const partnerBatchesRef = collection(db, DbCollection.PARTNERS, userPartnerID, DbCollection.BATCHES);
        const q = query(partnerBatchesRef, where('hereID', 'in', providerIDs), where('display', '==', true), where('verified', '==', true), where('collectionType', '==', "sortable_waste"));
        const batchesSnapshot = await getDocs(q);

        const batches: Batch[] = [];
        if (!batchesSnapshot.empty) {
            batchesSnapshot.forEach((batchDoc) => {
                const data = fromDbDoc(batchDoc);
                if (data) {
                    batches.push(data);
                }
            });
        }

        if (batches.length === 0) {
            dispatch(BatchesActions.setError('No batches found for this partner.'));
        } else {
            //Set all batches
            dispatch(BatchesActions.addBatches(batches));
            // Set filtered batches that has errors
            const filteredBatches = batches.filter(batch => {
                const errors = getErrors(errorsClasses, batch.results);
                const errorsCount = Object.values(errors).reduce((acc, count) => acc + count, 0);
                return errorsCount > 0;
            });
            dispatch(setFilteredBatches(filteredBatches));
        }
    } catch (error) {
        dispatch(BatchesActions.setError(JSON.stringify(error, null, 2)));
    } finally {
        dispatch(BatchesActions.stopLoading());
    }
};

/**
 * Fetches batch documents associated with a specific place ID from Firestore.
 * @param providerIDs - The address providerIDs to query for the bacthes at a place.
 * @param userPartnerID - The userPartnerID to query for batches from the associated partner.
 * @returns A thunk function for Redux to execute.
 */
export const fetchAllBatches = (userPartnerID: string) => async (dispatch: AppDispatch) => {
    dispatch(BatchesActions.startLoading());
    dispatch(BatchesActions.clearError());
    dispatch(BatchesActions.removeAllBatches());

    const errorsClasses = getSortingErrorClasses();

    try {
        const db = getFirestore();
        // Directly access the specific partner's batches collection
        const partnerBatchesRef = collection(db, DbCollection.PARTNERS, userPartnerID, DbCollection.BATCHES);
        const q = query(partnerBatchesRef, where('display', '==', true), where('verified', '==', true), where('collectionType', '==', "sortable_waste"));
        const batchesSnapshot = await getDocs(q);

        const batches: Batch[] = [];
        if (!batchesSnapshot.empty) {
            batchesSnapshot.forEach((batchDoc) => {
                const data = fromDbDoc(batchDoc);
                if (data) {
                    batches.push(data);
                }
            });
        }

        if (batches.length === 0) {
            dispatch(BatchesActions.setError('No batches found for this partner.'));
        } else {
            //Set all batches
            dispatch(BatchesActions.addBatches(batches));
            // Set filtered batches that has errors
            const filteredBatches = batches.filter(batch => {
                const errors = getErrors(errorsClasses, batch.results);
                const errorsCount = Object.values(errors).reduce((acc, count) => acc + count, 0);
                return errorsCount > 0;
            });
            dispatch(setFilteredBatches(filteredBatches));
        }
    } catch (error) {
        dispatch(BatchesActions.setError(JSON.stringify(error, null, 2)));
    } finally {
        dispatch(BatchesActions.stopLoading());
    }
};

/**
 * Fetches batch documents associated with a specific bin RFID from Firestore.
 * @param userPartnerID - The userPartnerID to query for batches from the associated partner.
 * @param binRFID - The RFID of the bin to filter by.
 * @returns A thunk function for Redux to execute.
 */
export const fetchBatchDocsByRFID = (userPartnerID: string,  binRFID: string) => async (dispatch: AppDispatch) => {
    dispatch(RfidsBatchesActions.startLoading());
    dispatch(RfidsBatchesActions.clearRfid());
    dispatch(RfidsBatchesActions.setRfidValue(binRFID!));

    const errorsClasses = getSortingErrorClasses();

    try {
        const db = getFirestore();
        // Directly access the specific partner's batches collection
        const partnerBatchesRef = collection(db, DbCollection.PARTNERS, userPartnerID, DbCollection.BATCHES);
        // Query by hereID, display, verified, collectionType, and binsRFIDs array containing the RFID
        const q = query(
            partnerBatchesRef,
            where('display', '==', true),
            where('verified', '==', true),
            where('collectionType', '==', "sortable_waste"),
            where('binsRFIDs', 'array-contains', binRFID)
        );

        const batchesSnapshot = await getDocs(q);
        const batches: Batch[] = [];

        if (!batchesSnapshot.empty) {
            batchesSnapshot.forEach((batchDoc) => {
                const data = fromDbDoc(batchDoc);
                if (data) {
                    batches.push(data);
                }
            });
        }

        if (batches.length === 0) {
            dispatch(RfidsBatchesActions.setError('No batches found for this RFID.'));
        } else {
            // Set all batches
            dispatch(RfidsBatchesActions.addBatches(batches));
            // Set filtered batches that have errors
            const filteredBatches = batches.filter(batch => {
                const errors = getErrors(errorsClasses, batch.results);
                const errorsCount = Object.values(errors).reduce((acc, count) => acc + count, 0);
                return errorsCount > 0;
            });
            dispatch(setFilteredBatches(filteredBatches));
        }
        dispatch(RfidsBatchesActions.showRfidBatches(true));
    } catch (error) {
        dispatch(RfidsBatchesActions.setError(JSON.stringify(error, null, 2)));
    } finally {
        dispatch(RfidsBatchesActions.stopLoading());
    }
};

/**
 * List all the batches from a given partner within the specified geohash bounds,
 * in order to display them on a map.
 */
const listPartnerBatchesForMapViewport = (partnerID: string, geohashBounds: string[], dangerLevel?: DangerLevel) => async (dispatch: AppDispatch) => {
    dispatch(SortingMapActions.startLoading());

    const db = getFirestore();
    const collectionPath = [DbCollection.PARTNERS, partnerID, DbCollection.BATCHES].join("/");

    const collectionRef = collection(db, collectionPath);
    const queries = geohashBounds.map(bounds =>
        query(
            collectionRef,
            where('display', '==', true),
            where('verified', '==', true),
            where('collectionType', '==', "sortable_waste"),
            where('position.hash', '>=', bounds[0]),
            where('position.hash', '<=', bounds[1])
        )
    );

    try {
        const querySnapshots = await Promise.all(queries.map(q => getDocs(q)));

        const batches: Batch[] = [];
        querySnapshots.forEach(snapshot => {
            snapshot.forEach(batchDoc => {
                const data = fromDbDoc(batchDoc);
                if (data) {
                    batches.push(data);
                }
            });
        });

        let { points, bounds } = formatBatchesForMap(batches, "sorting");
        const totalBatchesCount = batches.length;

        if (dangerLevel) {
            let filteredData = filterAddressesByDangerLevel(batches, dangerLevel);
            points = filteredData.points;
            bounds = filteredData.bounds;
        }

        dispatch(SortingMapActions.setMapPoints({ points, bounds, totalBatchesCount }));
    }
    catch (e) {
        dispatch(handleAPIError(e, "loading batches", SortingMapActions.setError));
    }
}


/**
 * List all the batches from a given partner matching all the selected filters,
 * in order to display them on a map
 */
const listAllPartnerBatchesForMap = (partnerID: string, dangerLevel?: DangerLevel) => async (dispatch: AppDispatch) => {
    dispatch(MissionMapActions.startLoading());

    const db = getFirestore();
    const collectionPath = [DbCollection.PARTNERS, partnerID, DbCollection.BATCHES].join("/");

    const collectionRef = collection(db, collectionPath);
    const q = query(collectionRef, where('display', '==', true), where('verified', '==', true), where('collectionType', '==', "sortable_waste"));

    try {
        const querySnapshot = await getDocs(q);

        let batches: Batch[];
        // sorting map where batches are clustered by address
        let { points, bounds, ...res } = formatBatchesForMap(querySnapshot.docs, "sorting");
        batches = res.batches;
        let totalBatchesCount = batches.length;
        if (dangerLevel) {
            let filteredData = filterAddressesByDangerLevel(batches, dangerLevel);
            points = filteredData.points;
            bounds = filteredData.bounds;
            totalBatchesCount = filteredData.totalBatchesCount;
        }

        dispatch(SortingMapActions.setMapPoints({ points, bounds, totalBatchesCount }));
    }
    catch (e) {
        dispatch(handleAPIError(e, "loading batches", BatchesActions.setError));
    }
}

/**
 * Filter the Addresses displayed on the map based on their number of errors.
 * @param dangerLevel Threshold for the minimum number of errors per address to be displayed.
 */
const filterAddressesByDangerLevel = (batches: Batch[], dangerLevel: DangerLevel) => {
    let { points } = formatBatchesForMap(batches, "sorting");

    let minLat = 999, minLng = 999, maxLat = -999, maxLng = -999;

    let filteredPoints: typeof points = [];
    let totalBatchesCount = 0;

    for (let point of points) {
        if (point.properties.errorsCount >= Number(dangerLevel)) {
            filteredPoints.push(point);

            totalBatchesCount += point.properties.batchesCount;

            const [lat, lng] = point.geometry.coordinates;

            // calculate map bounds and zoom to view all batches
            if (lat < minLat) minLat = lat;
            if (lat > maxLat) maxLat = lat;
            if (lng < minLng) minLng = lng;
            if (lng > maxLng) maxLng = lng;
        }
    }

    return {
        ...getCenterAndBounds(minLat, maxLat, minLng, maxLng),
        points: filteredPoints,
        totalBatchesCount,
    };
}

/** 
 * Object using `addressKey` as keys and storing addresses stats in values 
 */
type SortingPointsByAddressKey = { [addressKey: string]: AddressStatsPointFeature };


/**
 * Format a list of batches in order to be displayed on a map.
 * Return type changes depending if the map is:
 *  - For a single collection: 1 batch is represented as 1 point on the map
 *  - For global sorting: batches are grouped by address
 */
function formatBatchesForMap(batchesItems: Batch[] | QueryDocumentSnapshot[], mapType: "sorting"): { batches: Batch[], points: AddressStatsPointFeature[] } & CenterAndBounds;
function formatBatchesForMap(batchesItems: Batch[] | QueryDocumentSnapshot[]) {

    const errorsClasses = getSortingErrorClasses();

    let batches: Batch[] = [];
    let addressesPointsDict: SortingPointsByAddressKey = {};

    let minLat = 999, minLng = 999, maxLat = -999, maxLng = -999;

    for (const item of batchesItems) {
        const batch = item instanceof QueryDocumentSnapshot ? fromDbDoc(item) : item;
        batches.push(batch);

        const pos = batch.position;
        if (!pos || !batch.address) continue; // skip batches without GPS coordinates

        // cluster by hereID or formatted batch address (if hereID doesn't exist - legacy)
        const addressKey = getBatchKey(batch);

        // cluster by hereID or address
        addressesPointsDict[addressKey] = addBatchToSortingPointsDict(batch as PositionedBatch, addressesPointsDict, errorsClasses);


        // calculate map bounds and zoom to view all batches
        if (pos.latitude < minLat) minLat = pos.latitude;
        if (pos.latitude > maxLat) maxLat = pos.latitude;
        if (pos.longitude < minLng) minLng = pos.longitude;
        if (pos.longitude > maxLng) maxLng = pos.longitude;
    }
    // sorting map by addresses
    return {
        batches,
        points: Object.values(addressesPointsDict),
        ...getCenterAndBounds(minLat, maxLat, minLng, maxLng),
    };
}

/**
 * Add batch's data to existing sorting point for all batches at the same address
 */
function addBatchToSortingPointsDict(batch: PositionedBatch, addressesPointsDict: SortingPointsByAddressKey, errorsClasses: TrashType[]): AddressStatsPointFeature {    
    const getEmptyBatchPoint: (batch: PositionedBatch) => AddressStatsPointFeature = (batch) => ({
        type: "Feature",
        geometry: {
            type: "Point",
            coordinates: [batch.position.latitude, batch.position.longitude],
        },
        properties: {
            ...pick(batch, ["hereID", "address",]),
            batchesCount: 0,
            placeID: "",
            batchesWithErrorsCount: 0,
            batchesIDs: [],
            errors: {},
            errorsCount: 0,
            dangerLevel: DangerLevel.NONE,
        },
    });
    // cluster by hereID or formatted batch address (if hereID doesn't exist - legacy)
    const addressKey = getBatchKey(batch);

    let existing = addressesPointsDict[addressKey];
    if (!existing) {
        existing = getEmptyBatchPoint(batch);
    }

    const batchErrors = getErrors(errorsClasses, batch.results);
    const errorsCount = sum(Object.values(batchErrors));
    const addressErrorsCount = existing.properties.errorsCount + errorsCount;

    return {
        ...existing,
        properties: {
            ...existing.properties,
            batchesCount: existing.properties.batchesCount + 1,
            batchesWithErrorsCount: existing.properties.batchesWithErrorsCount + (errorsCount > 0 ? 1 : 0),
            batchesIDs: [...existing.properties.batchesIDs, batch.ID],
            errors: sumResults(existing.properties.errors, batchErrors),
            errorsCount: addressErrorsCount,
            dangerLevel: getDangerLevel(addressErrorsCount),
        }
    };
};


/**
 * Retrieve the danger level corresponding to a certain number of errors.
 */
export const getDangerLevel = (errorsCount: number) => {
    const thresholds = Object.values(DangerLevel);
    thresholds.reverse();
    for (let threshold of thresholds) {
        if (errorsCount >= Number(threshold)) return threshold;
    }
    return DangerLevel.NONE;
}

const BatchMethods = {
    fetchBatchDocs,
    fetchAllBatches,
    listAllPartnerBatchesForMap,
    listPartnerBatchesForMapViewport,
    fetchBatchDocsByRFID,
};

export default BatchMethods;