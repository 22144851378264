import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { RootState, AppDispatch } from './store';
import { useMemo } from 'react';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

/** Get the sorting errors preferences for the current partner. */
export const useSortingRules = () => {
  const errorsClasses = useAppSelector(state => state.sortingRules.errorsClasses);
  const mergingMapping = useAppSelector(state => state.sortingRules.mergingMapping);
  const displayedErrors = useAppSelector(state => state.sortingRules.displayedErrors);

  // Memoize the object combining the individual values
  return useMemo(() => ({
    errorsClasses,
    mergingMapping,
    displayedErrors,
  }), [errorsClasses, mergingMapping, displayedErrors]);
};
