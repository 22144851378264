import { DangerLevel } from "./geo";

/**
 * Colors representing the levels of threat represented by a number of sorting errors
 */
export const DANGER_COLORS = {
    /** 0 error */
    [DangerLevel.NONE]: "#5FB474",
    /** 1-3 errors */
    [DangerLevel.LOW]: "#ffc302",
    /** 4-6 errors */
    [DangerLevel.MEDIUM]: "#ff8f00",
    /** 7-10 errors */
    [DangerLevel.HIGH]: "#ff5b00",
    /** 11+ errors */
    [DangerLevel.VERY_HIGH]: "#ff0505",
};
