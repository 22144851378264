import CloseIcon from '@mui/icons-material/Close';
import { AppBar, Box, Slide, Toolbar } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import { TransitionProps } from "@mui/material/transitions";
import React, { useEffect, useState } from "react";
import { getPictureURL, StorageType } from "../../helpers/storage";


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const ImageComponent = ({ imagePath }: { imagePath: string }) => {
    const [url, setUrl] = useState('');
    const [open, setOpen] = useState(false);

    useEffect(() => {
        const fetchUrl = async () => {
            const result = await getPictureURL(imagePath, StorageType.BATCHES);
            setUrl(result || '');
        };
        fetchUrl();
    }, [imagePath]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <img
                alt="batch"
                src={url}
                onClick={handleClickOpen}
                style={{
                    cursor: 'pointer',
                    maxWidth: '100%',
                    height: 'auto',
                    objectFit: 'cover',
                    borderRadius: '0px',
                }}
            />
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'fixed', borderRadius: '0px', backgroundColor: "GrayText" }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Box style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: 'calc(100vh - 64px)',  // Subtracting the AppBar height
                }}>
                    <img
                        alt="batch enlarged"
                        src={url}
                        style={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                            objectFit: 'contain',
                        }}
                    />
                </Box>
            </Dialog>
            </>
    );
};
