import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MissionsData } from "../../../models/Missions";
import { LoadableContext, RootState } from "../../store";

const missionsAdapter = createEntityAdapter<MissionsData>({
    selectId: mission => mission.ID,
    sortComparer: (mission1, mission2) => {
        return mission1.name.localeCompare(mission2.name)
    }
});


const initialState: LoadableContext = {
    loading: false,
    error: null,
};


export const missionsSlice = createSlice({
    name: 'missions',
    initialState: missionsAdapter.getInitialState(initialState),
    reducers: {
        startLoadingList: (state) => {
            state.loading = true;
            state.error = null;
        },
        setList: (state, { payload: missions }: PayloadAction<MissionsData[]>) => {
            state.loading = false;
            missionsAdapter.setAll(state, missions);
        },
        addItem: (state, { payload: mission }: PayloadAction<MissionsData>) => {
            state.loading = false;
            missionsAdapter.addOne(state, mission);
        },
        updateItem: (state, { payload: { ID, data } }: PayloadAction<{ ID: string, data: Partial<MissionsData> }>) => {
            missionsAdapter.updateOne(state, {
                id: ID,
                changes: data,
            });
        },
        setError: (state, { payload }: PayloadAction<string>) => {
            state.loading = false;
            state.error = payload;
        },
    },
});

export const MissionsActions = missionsSlice.actions;

export const {
    selectAll: selectAllMissions,
    selectById: selectMissionById,
    selectIds: selectMissionsIds
} = missionsAdapter.getSelectors((state: RootState) => state.mission.list);

const MissionsReducer = missionsSlice.reducer;

export default MissionsReducer;