import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import BarcodeScanner from './BarcodeScanner';
import ScanPrompt from './ScanPrompt';
import ScanResult from './ScannerResult';
import StatsDrawer from '../AddressStats/StatsDrawer';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { showError } from '../../store/reducers/snacks';
import { RfidsBatchesActions } from '../../store/reducers/batches/rfid_batch_reducer';

export type PermissionStatus = "denied" | "prompt" | "granted" | null;

const ScanRfid: React.FC = () => {
    const [permissionStatus, setPermissionStatus] = useState<PermissionStatus>(null); // Camera permission status
    const rfid = useAppSelector(state => state.batch.rfid.rfidValue);
    const isScanning = useAppSelector(state => state.batch.rfid.scanning);
    const dispatch = useAppDispatch();


    const handleStartScan = async () => {
        if (!permissionStatus || permissionStatus === 'prompt') {
            // Request camera access
            try {
                const stream = await navigator.mediaDevices.getUserMedia({ video: true });
                setPermissionStatus('granted');
                stream.getTracks().forEach(track => track.stop()); // Stop the camera
                dispatch(RfidsBatchesActions.setStartScanning(true)); // Start the scanner
            } catch (err) {
                setPermissionStatus('denied');
            }
        } else {
            dispatch(RfidsBatchesActions.setStartScanning(true)); // Start scanning if permission already granted
        }
    };

    useEffect(() => {
        // Check camera permission on component load
        if (navigator.permissions) {
            navigator.permissions.query({ name: 'camera' as PermissionName }).then((permissionObj) => {
                setPermissionStatus(permissionObj.state);
            }).catch((error) => {
                dispatch(showError(error));
            });
        } else {
            // If navigator.permissions is not supported, handle unsupported browsers like Safari
            checkCameraPermission();
        }
    }, []);

    const checkCameraPermission = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: true });
            setPermissionStatus('granted');
            stream.getTracks().forEach(track => track.stop()); // Stop the camera immediately after permission check
        } catch (err) {
            setPermissionStatus('denied');
        }
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100vh',
                overflowY: 'auto',
                justifyContent: 'flex-start',
                backgroundColor: isScanning ? 'transparent' : 'rgba(0, 0, 0, 0.7)',
            }}
        >
            {isScanning ? (
                <BarcodeScanner />
            ) : rfid ? (
                <ScanResult />
            ) : (
                <ScanPrompt permissionStatus={permissionStatus} onStart={handleStartScan} />
            )}
            <StatsDrawer />
        </Box>
    );
};

export default ScanRfid;
