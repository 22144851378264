import { createSlice, PayloadAction } from '@reduxjs/toolkit';


export type AlgoliaSecrets = {
    appID: string | null,
    apiKey: string | null,
    loading: boolean,
    error: string | null,
}

const initialState: AlgoliaSecrets = {
    appID: null,
    apiKey: null,
    loading: false,
    error: null,
};

const algoliaSlice = createSlice({
    name: 'user',
    initialState: initialState,
    reducers: {
        startLoading: (state) => {
            state.loading = true;
        },
        stopLoading: (state) => {
            state.loading = false;
        },
        setError: (state, { payload: error }: PayloadAction<string>) => {
            state.loading = false;
            state.error = error;
        },
        resetError: (state) => {
            state.error = null;
        },
        setSecret: (state, { payload: data }: PayloadAction<AlgoliaSecrets>) => {
            state.loading = false;
            state.apiKey = data.apiKey;
            state.appID = data.appID;
        },
        clearSecret: (state) => {
            state.loading = false;
            state.apiKey = null;
            state.appID = null;
        }
    }
});

export const AlgoliaActions = algoliaSlice.actions;

const AlgoliaReducer = algoliaSlice.reducer;

export default AlgoliaReducer;