import { Check, ChevronLeft } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import MissionsMethods from "../../../actions/missions";
import { AppRoutes } from "../../../constants/routes";
import { Namespace } from "../../../locales/translations";
import { MissionsData, MissionStatus } from "../../../models/Missions";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import ActionButton from "../../_include/Buttons/ActionButton";
import MissionAddressList from "../MissionAddressList";
import MissionMap from "../MissionMap";


interface MissionDetailContentProps {
    mission: MissionsData;
    handleCheckboxChange: (addressKey: string) => void;
    handleAddressClick: (placeID: string) => void;
    onAddressSelected: (addressKey: string) => Promise<void>;
    navigate: (path: string) => void;
}

const MissionDetailContent: React.FC<MissionDetailContentProps> = ({
    mission,
    handleCheckboxChange,
    handleAddressClick,
    onAddressSelected,
    navigate,
}) => {

    const { t } = useTranslation([Namespace.ADDRESSES, Namespace.ACTIONS, Namespace.DATES]);
    const partnerID = useAppSelector((state) => state.user.partnerID);
    const loadingStatus = useAppSelector((state) => state.mission.selected.loadingStatus);
    const dispatch = useAppDispatch();

    const handleMarkAsDone = () => {
        if (partnerID) dispatch(MissionsMethods.updateMission(partnerID, mission.ID, { status: MissionStatus.COMPLETED }));
        navigate(AppRoutes.MISSIONS);
    };

    return (
        <Box padding={2} paddingBottom={10} sx={{ height: '100vh', overflowY: 'auto' }}>
            <Box display="flex" alignItems="center" gap={2}>
                <IconButton onClick={() => navigate(AppRoutes.MISSIONS)} style={{ padding: 0 }}>
                    <ChevronLeft />
                </IconButton>
                <Typography variant="h4" gutterBottom sx={{ pb: 1.5 }}>
                    {mission.name || t('Unnamed Mission')}
                </Typography>
            </Box>
            <Typography variant="body2" color="text.secondary">
                {t('created_at', { ns: Namespace.DATES, date: mission.createdAt })}
            </Typography>
            <Typography variant="body2" color="text.secondary" gutterBottom>
                {t('addresses_to_visit', { 
                    ns: Namespace.ADDRESSES, 
                    count: mission.addresses?.filter(a => a.visited)?.length || 0,
                    total: mission.addresses?.length || 0,
                })}
            </Typography>
            <Typography variant="body1" fontWeight="bold" gutterBottom>
                {t('deadline', { ns: Namespace.DATES, date: mission.deadline })}
            </Typography>
            {mission.addresses && mission.addresses.length > 0 ? (
                <div style={{ height: 500, width: "100%" }}>
                    <MissionMap
                        mapID={mission.ID}
                        width={1000}
                        height={500}
                        points={mission.addresses}
                        showMapControls={true}
                        onMarkerClick={onAddressSelected}
                    />
                </div>
            ) : (
                <Typography variant="body2" color="text.secondary">
                    {t('No addresses available for this mission.')}
                </Typography>
            )}
            <MissionAddressList
                onCheckboxChange={handleCheckboxChange}
                onAddressClick={handleAddressClick}
                onAddressDetailClick={onAddressSelected}
            />

            {mission.status !== MissionStatus.COMPLETED && (
                <Box display="flex" justifyContent="center" marginTop={2}>
                    <ActionButton
                        onClick={handleMarkAsDone}
                        color='success'
                        startIcon={<Check />}
                        loading={loadingStatus}
                    >
                        {t('mark_as_done', { ns: Namespace.ACTIONS })}
                    </ActionButton>
                </Box>
            )}
        </Box>
    )
};

export default MissionDetailContent;
