import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Locale } from '../../locales/translations';
import { NewUserData, UserRoles } from '../../models/User';
import { Coords } from './batches/map';


type UserContext = {
    isAuthenticated: boolean;
    isAdmin: boolean;
    viewAsPartner: boolean;
    locale: Locale;
    partnerID: string | null;
    loading: boolean;
    secretLoading: boolean;
    error: string | null;
    secret: string | null;
    email: string | null;
    location: Coords | null
};

const initialState: UserContext = {
    isAuthenticated: false,
    isAdmin: false,
    viewAsPartner: false,
    locale: Locale.FRENCH,
    partnerID: null,
    loading: false,
    secretLoading: false,
    error: null,
    secret: null,
    email: null,
    location: null,
};

const userSlice = createSlice({
    name: 'user',
    initialState: initialState,
    reducers: {
        startLoading: (state) => {
            state.loading = true;
        },
        stopLoading: (state) => {
            state.loading = false;
        },
        startSecretLoading: (state) => {
            state.secretLoading = true;
        },
        stopSecretLoading: (state) => {
            state.secretLoading = false;
        },
        setError: (state, { payload: error }: PayloadAction<string>) => {
            state.loading = false;
            state.secretLoading = false;
            state.error = error;
        },
        resetError: (state) => {
            state.error = null;
        },
        setUser: (state, { payload: data }: PayloadAction<NewUserData>) => {
            state.loading = false;
            state.isAuthenticated = true;
            state.isAdmin = data.role === UserRoles.ADMIN;
            state.locale = data.locale;
            state.partnerID = data.partnerID;
            state.email = data.email 
        },
        setSecret: (state, { payload: secret }: PayloadAction<string>) => {
            state.secretLoading = false;
            state.secret = secret;
        },
        setUserLocation: (state, { payload: location }: PayloadAction<Coords>)=>{
            state.location = location;
        },
        clearUser: (state) => {
            state.loading = false;
            state.isAuthenticated = false;
            state.isAdmin = false;
            state.partnerID = null;
        }
    }
});

export const UserActions = userSlice.actions;

const UserReducer = userSlice.reducer;

export default UserReducer;