import i18next from "i18next";
import moment from "moment";
import { initReactI18next } from 'react-i18next';
import { ucFirst } from "../helpers/strings";

const momentLocales = {
    fr: require('moment/locale/fr'),
    en: require('moment/locale/en-gb'),
};

export enum Locale {
    FRENCH = "fr",
    ENGLISH = "en",
}

export const LOCALES_LABELS = {
    [Locale.ENGLISH]: "English",
    [Locale.FRENCH]: "Français",
}

export enum Namespace {
    ADDRESSES = "addresses",
    DASHBOARD = "dashboard",
    SNACKBARS = "snackbars",
    /** Labels for buttons (eg "filter", "confirm") */
    ACTIONS = 'actions',
    /** Related to user-defined areas (eg "Qu'est-ce qu'une zone ?"...) */
    AREAS = 'areas',
    /** Labels for charts (eg "Evolution du tri"...) */
    CHARTS = 'charts',
    /** Labels and strings about collections (eg "Pas de collecte"...) */
    COLLECTIONS = 'collections',
    /** Common words (eg "points", ...) */
    COMMONS = 'commons',
    /** Related to contacts management (eg "ceo", "developer") */
    CONTACTS = 'contacts',
    /** Time and dates display (eg "depuis 3 semaines") */
    DATES = 'dates',
    /** Confirmation dialogs (eg "Confirmer que ... ?") */
    DIALOGS = 'dialogs',
    /** Forms placeholders/labels (eg "Entrez votre adresse...") */
    FORMS = 'forms',
    /** Ficha specific words (eg "Levée de bac"...) */
    GLOSSARY = 'glossary',
    /** Related to batches images (eg "Télécharger les images"...) */
    IMAGES = 'images',
    /** Words related to maps (eg "Légende", "Marqueur"...) */
    MAPS = 'maps',
    /** Vocabulary related to clients/partners (eg "Partenaire"...) */
    PARTNERS = 'partners',
    /** Vocabulary related to user's preferences (eg "Shortcuts"...) */
    PREFERENCES = 'preferences',
    /** Messages for the global snackbar (eg "Action successful!") */
    SNACKS = 'snacks',
    /** eg "kg of CO2"... */
    STATS = 'stats',
    /** eg "Chats", "Statistiques"... */
    TITLES = 'titles',
    /** Types of waste (eg "plastic bottle", ...) */
    WASTES = 'wastes',
    RFID ='rfid',
}

const resources = {
    fr: {
        [Namespace.ACTIONS]: require('./fr/actions.json'),
        [Namespace.ADDRESSES]: require('./fr/addresses.json'),
        [Namespace.COMMONS]: require('./fr/commons.json'),
        [Namespace.DASHBOARD]: require('./fr/dashboard.json'),
        [Namespace.DATES]: require('./fr/dates.json'),
        [Namespace.SNACKS]: require('./fr/snacks.json'),
        [Namespace.TITLES]: require('./fr/titles.json'),
        [Namespace.WASTES]: require('./fr/wastes.json'),
        [Namespace.STATS]: require('./fr/stats.json'),
        [Namespace.CHARTS]: require('./fr/charts.json'),
        [Namespace.GLOSSARY]: require('./fr/glossary.json'),
        [Namespace.FORMS]: require('./fr/forms.json'),
        [Namespace.RFID]: require('./fr/rfid.json'),

    },
    en: {
        [Namespace.ACTIONS]: require('./en/actions.json'),
        [Namespace.ADDRESSES]: require('./en/addresses.json'),
        [Namespace.COMMONS]: require('./en/commons.json'),
        [Namespace.DASHBOARD]: require('./en/dashboard.json'),
        [Namespace.DATES]: require('./en/dates.json'),
        [Namespace.SNACKS]: require('./en/snacks.json'),
        [Namespace.TITLES]: require('./en/titles.json'),
        [Namespace.WASTES]: require('./en/wastes.json'),
        [Namespace.STATS]: require('./en/stats.json'),
        [Namespace.CHARTS]: require('./en/charts.json'),
        [Namespace.GLOSSARY]: require('./en/glossary.json'),
        [Namespace.FORMS]: require('./en/forms.json'),
        [Namespace.RFID]: require('./en/rfid.json'),
    }
}

export const format = (value: any, format: string | undefined, lng: string | undefined) => {
    if (format === "number") {
        return new Intl.NumberFormat(lng, {
            style: 'decimal',
            maximumFractionDigits: 2,
        }).format(value);
    }
    if (format === "currency" || format === "rounded_currency") {
        const fractionDigits = format === "rounded_currency" ? 0 : 2;
        return value.toLocaleString(lng, {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: fractionDigits,
            maximumFractionDigits: fractionDigits,
        })
    }
    if (format === "date") {
        return moment(value).format("LL");
    }
    if (format === "day") {
        return moment(value).format("ddd D MMM");
    }
    if (format === "day_full") {
        return ucFirst(moment(value).format("dddd D MMMM"));
    }
    if (format === "week") {
        return `${moment(value).startOf("week").format("D MMM")}-${moment(value).endOf("week").format("D MMM")}`;
    }
    if (format === "week_full") {
        return `Semaine du ${moment(value).startOf("week").format("D MMMM")} au ${moment(value).endOf("week").format("D MMMM")}`;
    }
    if (format === "month") {
        return ucFirst(moment(value).format("MMMM"));
    }
    return value;
}

export const LOCAL_STORAGE_LOCALE_KEY = "preferred_locale";

export const configureTranslations = () => {
    i18next
        .use(initReactI18next)
        .init({
            resources,
            lng: localStorage.getItem(LOCAL_STORAGE_LOCALE_KEY) || 'fr',
            supportedLngs: Object.values(Locale),
            load: 'languageOnly',
            // debug: true,
            interpolation: {
                format: format,
                escapeValue: false,
            }
        });
}