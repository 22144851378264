import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import { getAuth } from "firebase/auth";
import { UNAUTHORIZED_ERROR } from "../constants/api_errors";
import { showError } from "../store/reducers/snacks";
import { AppDispatch } from "../store/store";

export interface Error {
    code: number;
    body: any;
}

export class CodeError extends Error {
    code: number;

    constructor(code: number, message: string) {
        super(message);
        this.code = code;
    }
}

export async function fetchAPI(input: RequestInfo, init?: RequestInit): Promise<any> {
    try {
        // Retrieve API token
        const auth = getAuth();
        const user = auth.currentUser;
        if (!user) {
            console.error("No authenticated user found");
            return { code: 401, body: UNAUTHORIZED_ERROR };
        }

        let token = await user.getIdToken();
        if (!token) {
            console.error("Failed to retrieve token");
            return { code: 401, body: UNAUTHORIZED_ERROR };
        }

        let headers = {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            ...init?.headers,
        };

        const res = await fetch(input, {
            ...init,
            headers: headers,
        });

        return await handleAPIResponse(res);
    } catch (e) {
        console.error("Error in fetchAPI:", e);
        return handleFetchAPIError(e);
    }
}

/**
 * Handle different types of fetch API errors gracefully
 * @param error The error thrown during fetch API call
 */
const handleFetchAPIError = (error: any): Error => {
    if (error instanceof CodeError) {
        return { code: error.code, body: error.message };
    }

    return { code: 500, body: "Internal Server Error" };
};

/**
 * Format body of the response to be readable.
 * Throw an Error to be caught and handled in reducers upon API errors (e.g. 400, 404...)
 * @param res The Response of the request to the API
 */
export const handleAPIResponse = async (res: Response): Promise<any> => {
    try {
        const contentType = res.headers.get("Content-Type");

        if (contentType?.includes("application/json")) {
            const json = await res.json();
            if (!res.ok) {
                return { code: res.status, body: json };
            }
            return json;
        } else {
            const text = await res.text();
            if (!res.ok) {
                return { code: res.status, body: text };
            }
            return text;
        }
    } catch (e) {
        console.error("Error handling API response:", e);
        return { code: res.status, body: "Failed to parse response" };
    }
};

/**
 * Error handler for API request, displaying an error snackbar 
 * and setting the error field of the given reducer
 * @param e The thrown error
 * @param action A displayable name for the action to identify the log
 * @param 
 */
export const handleAPIError = (e: unknown, action: string, reducerErrorAction: ActionCreatorWithPayload<string, string>) => (dispatch: AppDispatch) => {
    const error = e as Error;
    console.error("Failed", action, error);
    dispatch(reducerErrorAction(error.body));

    // show error message
    dispatch(showError(error.body));
};