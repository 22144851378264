import { Marker } from "@googlemaps/markerclusterer";
import { darken } from "@mui/material";
import { AdvancedMarker } from "@vis.gl/react-google-maps";
import { useCallback, useEffect } from "react";
import { slugify } from "../../helpers/strings";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { MissionMapActions } from "../../store/map_reducer";
import MarkerIcon from "../_include/icons/MarkerIcon";

type AddressMarkerProps = {
    position: google.maps.LatLngLiteral;
    addressKey: string;
    providerId?: string;
    color: string;
    zIndex?: number;
    onClick: (addressKey: string, position?: google.maps.LatLngLiteral,  providerIDs?: string[], ) => void;
    setMarkerRef?: (marker: Marker | null, key: string) => void;
}

const MARKER_SIZE = 28;
const SELECTED_MARKER_SIZE = 36;

export default function AddressMarker({ position, addressKey, providerId, color, zIndex = 1, onClick, setMarkerRef }: AddressMarkerProps) {
    const dispatch = useAppDispatch();

    const selected = useAppSelector(state => state.batch.map.selectedAddressKey === addressKey);
    const markerSize = selected ? SELECTED_MARKER_SIZE : MARKER_SIZE;

    const addressClicked = useAppSelector(state => state.mission.map.selectedAddressKey === addressKey);
    useEffect(() => {
        if (selected) dispatch(MissionMapActions.selectAddressKey(null)); // remove selected place ID to only trigger once
        if (addressClicked) onClick(addressKey, position); // zoom on marker when address clicked in list
    }, [addressClicked, position, addressKey, dispatch, onClick]);

    const slugedAddressKey = slugify(addressKey);

     /**
     * Handles the click event on the marker.
     * Toggles the tooltip visibility and triggers data fetch actions for search map.
     */
    const handleMarkerClick = () => {
        onClick(addressKey, position, providerId ? [providerId] : undefined);
    };

    const ref = useCallback(
        (marker: google.maps.marker.AdvancedMarkerElement | null) => {
            if (setMarkerRef) {
                setMarkerRef(marker, addressKey);
            }
        },
        [setMarkerRef, addressKey]
    );
    
    return (
        <AdvancedMarker 
            position={position} 
            clickable 
            onClick={handleMarkerClick}
            ref={ref}
            zIndex={selected ? zIndex + 99 : zIndex}
            >
            <svg width={0} height={0}>
                <linearGradient id={`linearColors-${slugedAddressKey}`} x1={1} y1={0} x2={1} y2={1}>
                    <stop offset={0} stopColor={color} />
                    <stop offset={1} stopColor={darken(color,.3)} />
                </linearGradient>
            </svg>
                <MarkerIcon
                    sx={{
                        mt: `${-markerSize}px`,
                        ml: `${-markerSize / 2}px`,
                        filter: `drop-shadow(2px 2px 2px rgb(0, 0, 0, .2))`,
                        fill: `url(#linearColors-${slugedAddressKey})`,
                        cursor: "pointer",
                        fontSize: markerSize,
                        ...(selected && { // white stroke around selected marker
                            stroke: "#fff",
                            strokeWidth: 1,
                        }),
                    }}
                />
            {/* </Tooltip> */}
        </AdvancedMarker>
    )
}
