import { ExpandMore } from '@mui/icons-material';
import { Card, CardContent, CardHeader, Collapse, IconButton } from '@mui/material';
import { EntityId } from '@reduxjs/toolkit';
import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getErrors } from '../../helpers/trash';
import { Namespace } from '../../locales/translations';
import { useAppSelector, useSortingRules } from '../../store/hooks';
import { selectBatchById } from '../../store/reducers/batches/batch_reducer';
import { ImageComponent } from './ImageViewer';
import { selectRfidBatchById } from '../../store/reducers/batches/rfid_batch_reducer';

type BatchCardProps = {
    batchID: EntityId;
    index: number;
};

const BatchCard: React.FC<BatchCardProps> = ({ batchID, index}) => {
    const { errorsClasses } = useSortingRules();

    const [expanded, setExpanded] = useState(false);  // Local state for expanded
    const showRfid = useAppSelector(state => state.batch.rfid.showRfidsBatches);
    const { t } = useTranslation([Namespace.COMMONS, Namespace.DATES, Namespace.WASTES]);
   
    // Fetch either batch or rfidBatch based on showRfid prop
    const batch = useAppSelector(state => 
        showRfid ? selectRfidBatchById(state, batchID) : selectBatchById(state, batchID)
    );

    const handleExpandClick = () => {
        setExpanded(prevExpanded => !prevExpanded);  // Toggle expanded state
    };

    // Check if batch is undefined
    if (!batch) {
        return null;
    }

    const errors = getErrors(errorsClasses, batch.results);
    const errorsCount = Object.values(errors).reduce((acc, count) => acc += count, 0);

    /**
     * Title for the card in the format "DD MMMM YYYY - x errors"
     */
    const cardTitle = t("date_date", { 
        date: moment(batch.timestamp, 'x'), 
        ns: Namespace.DATES 
    }) + ' - ' + t("errors_count", { 
        count: errorsCount,
        ns: Namespace.COMMONS 
    });

    return (
        <Card elevation={2} key={batch.timestamp|| index}>
            <CardHeader
                title={cardTitle}
                titleTypographyProps={{
                    variant: "h5"
                }}
                subheader={
                    Object.entries(errors).map(([errorType, count]) => (
                        t(`${errorType}_count`, { count: count, ns: Namespace.WASTES })
                    )).join(', ')
                }
                subheaderTypographyProps={{
                    variant: "body2"
                }}
                action={
                    <IconButton
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                    >
                        <ExpandMore />
                    </IconButton>
                }
            />
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                    <ImageComponent imagePath={batch.imageURL} />
                </CardContent>
            </Collapse>
        </Card>
    );
};

export default BatchCard;
