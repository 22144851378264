import { Add, Check } from "@mui/icons-material";
import { Chip, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Namespace } from "../../../locales/translations";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { selectBatchIds } from "../../../store/reducers/batches/batch_reducer";
import { selectFilteredBatchCount, toggleFilterErrors } from "../../../store/reducers/batches/filtered_batches";

const FilterChip: React.FC = () => {
    const dispatch = useAppDispatch();
    const showFilterData = useAppSelector(state => state.batch.filter.showFilter);
    const filteredBatchesCount = useAppSelector(selectFilteredBatchCount);
    const batchesCount = useAppSelector(state => selectBatchIds(state).length);
    const { t } = useTranslation([Namespace.ACTIONS]);

    const handleFilterChange = () => {
        dispatch(toggleFilterErrors());
    };

    const batchNumberToRender = showFilterData ? filteredBatchesCount : batchesCount;

    return (
        <Stack direction="row" spacing={1} alignItems="center">
            <Chip
                label={t('sorting mistakes only', { ns: Namespace.ACTIONS })}
                variant="outlined"
                onClick={handleFilterChange}
                color={showFilterData ? 'success' : 'default'}
                sx={{ borderRadius: 2, px: 2, py: 0.5 }}
                icon={showFilterData ? <Check /> : <Add />}
            />
            <Typography variant="body2" color="textSecondary">
              {t(`images_count`,{count: batchNumberToRender, ns: Namespace.COMMONS })}
            </Typography>
        </Stack>
    );
};

export default FilterChip;
