import { Box, CircularProgress } from '@mui/material';
import { useAppSelector } from '../../store/hooks';
import { selectAllSortingPoints } from '../../store/reducers/batches/map';
import SearchMap from '../Map/SearchMap';
import SearchBar from './SearchBar';
import StatsDrawer from './StatsDrawer';

const AddressSearch: React.FC = () => {
    const loading = useAppSelector((state) => (state.user.secretLoading || state.algolia.loading));
    const batchAddresses = useAppSelector(selectAllSortingPoints);

    if (loading) {
        return (
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', minWidth: '100%', padding: '10px', gap: '10px', minHeight: "100vh" }}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', minWidth: '100%', gap: '10px', minHeight: "calc(100vh - 56px)", position: 'relative' }}>
            <Box sx={{ zIndex: 10, position: 'relative' }}>
                <SearchBar />
            </Box>
            <Box sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}}>
                <SearchMap
                    mapID="search-map"
                    points={batchAddresses}
                    showMapControls={true}
                />
            </Box>
            <StatsDrawer/>
        </Box>
    );
}

export default AddressSearch;
