import { max, sum } from "lodash";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { WASTES_COLORS } from "../../../constants/trash";
import { getAggregatedErrors } from "../../../helpers/trash";
import { Namespace } from "../../../locales/translations";
import { useAppSelector, useSortingRules } from "../../../store/hooks";
import { selectAllBatches } from "../../../store/reducers/batches/batch_reducer";
import MostFrequentErrorsChart, { BAR_COLORS, ERROR_CATEGORY, ErrorPoint, TEXT_COLORS } from "./MostFrequentErrorsChart";

type WrapperProps = {
    height: number;
}

export type WasteSlice = {
    id: string;
    label: string;
    value: number;
    color: string;
}

export default function MostFrequentErrorsWrapper({ height }: WrapperProps) {
    const batches = useAppSelector(state => selectAllBatches(state));

    const { errorsClasses } = useSortingRules();

    const { t } = useTranslation([Namespace.WASTES]);

    const data: ErrorPoint[] = useMemo(() => {
        let errorsTypes: ErrorPoint[] = [];
        if (!batches) return errorsTypes; // results not loaded yet

        const aggregatedErrors = getAggregatedErrors(errorsClasses, batches);
        const maxErrorCount = max(Object.values(aggregatedErrors))!;

        for (const errorType of errorsClasses) {
            const count = aggregatedErrors[errorType] ?? 0;
            const portion = count / maxErrorCount;
            let category: ERROR_CATEGORY;

            if (portion > 0.8) category = ERROR_CATEGORY.WORST;
            else if (portion > 0.4) category = ERROR_CATEGORY.BAD;
            else if (portion > 0.01) category = ERROR_CATEGORY.MEDIUM;
            else category = ERROR_CATEGORY.GOOD;

            errorsTypes.push({
                trashType: errorType,
                count,
                color: BAR_COLORS[category],
                textColor: TEXT_COLORS[category],
            });
        }

        return errorsTypes.sort((p1, p2) => p1.count - p2.count); // sort by items count desc
    }, [batches]);

    const pieData: WasteSlice[] = useMemo(() => {
        let wastesList: WasteSlice[] = [];
        if (!batches) return wastesList; // results not loaded yet

        let mergedResults = getAggregatedErrors(errorsClasses, batches);
        let totalErrors = sum(Object.values(mergedResults));

        for (const errorType of errorsClasses) { // 1 slice: 1 type of error
            const count = mergedResults[errorType] ?? 0;
            const value = Math.round(count / totalErrors * 100);
            if (value > 0) { // only add slices strictly positive values
                wastesList.push({
                    id: errorType,
                    label: t(errorType, { ns: Namespace.WASTES }),
                    value,
                    color: WASTES_COLORS[errorType],
                });
            }
        }

        return wastesList;
    }, [t, batches]);

    return (
        <MostFrequentErrorsChart data={data} pieData={pieData} icons={true} height={(theme) => theme.spacing(height)} />
    );
}
