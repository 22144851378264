import { EntityState, PayloadAction, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { Batch } from '../../../models/Batches';
import { RootState } from '../../store';


const filteredBatchesAdapter = createEntityAdapter<Batch>({
    selectId: (batch) => batch.timestamp,
    sortComparer: (a, b) => b.timestamp - a.timestamp, // sort batches by timestamp desc
});

interface FilterState {
    showFilter: boolean;
    filteredBatchCount: number;
}

type FilteredBatchState = EntityState<Batch> & FilterState;

const initialState: FilteredBatchState = filteredBatchesAdapter.getInitialState({
    showFilter: true,
    filteredBatchCount: 0,
});

const filterSlice = createSlice({
    name: 'batch_filter',
    initialState,
    reducers: {
        setFilteredBatches(state, action: PayloadAction<Batch[]>) {
            filteredBatchesAdapter.setAll(state, action.payload);
            state.filteredBatchCount = action.payload.length;
        },
        toggleFilterErrors(state) {
            state.showFilter = !state.showFilter;
        },
    },
});

export const { setFilteredBatches, toggleFilterErrors } = filterSlice.actions;

const FilterReducer = filterSlice.reducer;

export default  FilterReducer;

export const {
    selectAll: selectAllFilteredBatches,
    selectIds: selectFilteredBatchIds,
} = filteredBatchesAdapter.getSelectors((state: RootState) => state.batch.filter);

export const selectFilterErrors = (state: RootState) => state.batch.filter.showFilter;
export const selectFilteredBatchCount = (state: RootState) => state.batch.filter.filteredBatchCount;
