import { Error } from '../actions/actions';

export const BAD_REQUEST_ERROR: Error = {
    code: 400,
    body: "Bad request"
};

export const UNAUTHORIZED_ERROR: Error = {
    code: 401,
    body: "Unauthorized"
};

export const NOT_FOUND_ERROR: Error = {
    code: 404,
    body: "Not Found"
};

export const CONFLICT_ERROR: Error = {
    code: 409,
    body: "Conflict"
};

export const FIREBASE_WRONG_PASSWORD = "auth/wrong-password";