import { ChevronLeft } from '@mui/icons-material';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Namespace } from '../../locales/translations';

type BackButtonProps = {
    onClick: () => void;
};

export const BackButton: React.FC<BackButtonProps> = ({ onClick }) => {
    const { t } = useTranslation([Namespace.ACTIONS]);

    return (
        <Button
            onClick={onClick}
            variant="text"
            color="secondary"
            sx={{
                backgroundColor: "transparent",
                padding: "10px 0",
                fontSize: "16px"
            }}
            startIcon={<ChevronLeft />}
        >
            {t("back", { ns: Namespace.ACTIONS })}
        </Button>
    );
};
