import { doc, getDoc, getFirestore } from "firebase/firestore";
import { DbCollection } from "../constants/db";
import { BatchesActions } from "../store/reducers/batches/batch_reducer";
import { PartnerActions } from "../store/reducers/partner/partner_reducer";
import { AppDispatch } from "../store/store";
import Partner, { PartnerDbData } from "../models/Partner";
import { SortingRulesActions } from "../store/reducers/sorting_rules/sorting_rules";

/**
 * Fetches the partner document from Firestore.
 * @param userPartnerID - The userPartnerID to query for the partner document.
 * @returns A thunk function for Redux to execute.
 */
export const fetchPartnerDoc = (userPartnerID: string) => async (dispatch: AppDispatch) => {
    dispatch(BatchesActions.startLoading());
    dispatch(BatchesActions.clearError());

    try {
        const db = getFirestore();
        const partnerDocRef = doc(db, DbCollection.PARTNERS, userPartnerID);
        const partnerDocSnap = await getDoc(partnerDocRef);

        if (partnerDocSnap.exists()) {
            const partnerData = partnerDocSnap.data() as PartnerDbData;
            const viewport = partnerData.defaultMapViewport;
            const partner: Partner = {
                ...partnerData,
                defaultMapViewport: viewport ? {
                    center: { latitude: viewport.center.latitude, longitude: viewport.center.longitude },
                    zoom: viewport.zoom,
                } : undefined,
            };
            dispatch(PartnerActions.setPartner(partner));
            
            // apply the partner's specific sorting rules
            dispatch(SortingRulesActions.setRules(partner.sortingRules));

        } else {
            dispatch(BatchesActions.setError('Partner document not found.'));
        }
    } catch (error) {
        dispatch(BatchesActions.setError(JSON.stringify(error, null, 2)));
    } finally {
        dispatch(BatchesActions.stopLoading());
    }
};
