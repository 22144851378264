import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { useTranslation } from 'react-i18next';
import { Namespace } from '../../locales/translations';
import { RfidsBatchesActions } from '../../store/reducers/batches/rfid_batch_reducer';
import BatchMethods from '../../actions/batch_action';
import SvgMask from './SvgMask';

interface ScanResultProps {
}

const ScanResult: React.FC<ScanResultProps> = ({  }) => {
    const rfid = useAppSelector(state => state.batch.rfid.rfidValue);
    const screenshot = useAppSelector(state => state.batch.rfid.screenshot);
    const partnerID = useAppSelector((state) => (state.user.partnerID));
    const { t } = useTranslation([Namespace.RFID]);
    const dispatch = useAppDispatch();

    const handleConfirm = () => {
        if (rfid) {
            dispatch(BatchMethods.fetchBatchDocsByRFID(partnerID!, rfid));
        }
    };
    const handleReset = () => {
        dispatch(RfidsBatchesActions.clearRfid());
    };

    return (
        <Box
            sx={{
                width: '100%',
                height: '100vh',
                backgroundImage: `url(${screenshot})`,
                backgroundSize: 'cover',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                zIndex: 1,
            }}
        >
           {/* Use the reusable SvgMask component */}
           <SvgMask />
            <Box
                sx={{
                    position: 'absolute',
                    bottom: '15%',
                    width: '100%',
                    textAlign: 'center',
                    color: 'transparent',
                    zIndex: 2,
                }}
            >
                <Typography variant="h3" color='#ffff' sx={{ marginBottom: '20px', fontWeight: 600, }}>
                    {t('barcode_read', { ns: Namespace.RFID })}
                </Typography>

                <Typography variant="h3" color='#ffff'>{rfid}</Typography>

                <Button
                    variant="contained"
                    sx={{
                        marginBottom: '10px', width: '240px', backgroundColor: '#336699', '&:hover': {
                            backgroundColor: '#336699', // Match hover color to default background
                        },
                    }}
                    onClick={handleConfirm}
                >
                    {t('confirm', { ns: Namespace.ACTIONS })}
                </Button>
                <br />
                <Button variant="text" sx={{ width: '253px', color: '#fff', }} onClick={handleReset}>
                    {t('cancel', { ns: Namespace.ACTIONS })}
                </Button>
            </Box>
        </Box>
    );
};

export default ScanResult;
