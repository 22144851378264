import FindReplaceRoundedIcon from '@mui/icons-material/FindReplaceRounded';
import { LoadingButton } from "@mui/lab";
import { ControlPosition, MapControl } from "@vis.gl/react-google-maps";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import BatchMethods from "../../actions/batch_action";
import { Namespace } from "../../locales/translations";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { SortingMapActions } from "../../store/reducers/batches/map";

export default function SearchAreaButton() {
    const { t } = useTranslation([Namespace.ACTIONS]);
    
    const showSearchAreaButton = useAppSelector(state => state.batch.map.showSearchAreaButton);
    const loading = useAppSelector(state => state.batch.map.loading);

    const partnerID = useAppSelector(state => state.user.partnerID);

    const dispatch = useAppDispatch();

    /**
     * Handles the "Search this area" button click by fetching batches within the current geohash bounds.
     */
    const handleSearchThisArea = () => {
        if (!partnerID) return;

        const geohashBounds = JSON.parse(sessionStorage.getItem('geohashBounds')!);
        if(geohashBounds){ 
        dispatch(BatchMethods.listPartnerBatchesForMapViewport(partnerID, geohashBounds))
            .then(() => {
                dispatch(SortingMapActions.toggleShowSearchAreaButton(false));
            });
        }
    };

    useEffect(() => {
        handleSearchThisArea();
    }, []);

    if (!showSearchAreaButton) return null;

    return (
        <MapControl position={ControlPosition.TOP_CENTER}>
            <LoadingButton
                onClick={handleSearchThisArea}
                loading={loading}
                loadingPosition="start"
                variant="contained"
                sx={{
                    mt: 10,
                    bgcolor: "#fff",
                    boxShadow: 4,
                    borderRadius: 8,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 1,
                    color: theme => theme.palette.info.main,
                    '&:hover': {
                        bgcolor: "#fff", // No hover color change
                        boxShadow: 6,
                    },
                    '&:active': {
                        bgcolor: "#f0f0f0",
                    },
                }}
                startIcon={<FindReplaceRoundedIcon />}
            >
                {t("search_area", { ns: Namespace.ACTIONS })}
            </LoadingButton>
        </MapControl>
    )
}