import _, { omit } from "lodash";
import moment from "moment";
import { DEFAULT_MERGING_WASTE_MAPPING, DEFAULT_SORTING_RULES, MergingWasteMapping, SortingRules, TrashCount, TrashType } from "../constants/trash";
import { Batch, BatchResults } from "../models/Batches";
import Partner from "../models/Partner";
import { CollectionType } from "../constants/collections";
import { getStoreState } from "../store/store";

/**
 * Apply some sorting rules in the context of a collection type to list sorting errors.
 */
export function applySortingRules(sortingRules: Partner["sortingRules"], collectionType: CollectionType) {
    let sortingErrors: SortingRules = {
        ...DEFAULT_SORTING_RULES,
        ...sortingRules,
    };

    return Object.values(TrashType).filter(trashType => !sortingErrors[trashType].includes(collectionType));
} 

/**
 * Get the classes of waste that should be considered as errors for a given partner.
 */
export const getSortingErrorClasses = () => {
    const partner = getStoreState().partner.partner;
    const collectionType = CollectionType.SORTABLE_WASTE;

    return applySortingRules(partner?.sortingRules, collectionType);
}

/**
 * Simply check if a string matches one of the sorting errors in the current context. 
 * @param sortingErrors List of classes of waste considered as errors in the current context.
 * @param trash The class of waste to consider.
 */
export const isSortingError = (sortingErrors: string[], trash: string) => sortingErrors.includes(trash);

/**
 * Extract the counts of errors from some waste results object
 */
export function getErrors(sortingErrors: TrashType[], results: BatchResults): Partial<Record<TrashType, number>> {
    return Object.entries(results).reduce((acc, [trashType, count]) => {
        if (isSortingError(sortingErrors, trashType) && count > 0) {
            acc[trashType as TrashType] = count;
        }
        return acc;
    }, {} as Partial<Record<TrashType, number>>);
}

export function hasErrors(results: BatchResults): boolean {
    return Object.values(results).some(count => count && count > 0);
}


/**
 * Aggregate the counts of errors from a list of batches
 */
export function getAggregatedErrors(sortingErrors: TrashType[], batches: Batch[]): Partial<Record<TrashType, number>> {
    const aggregatedErrors: Partial<Record<TrashType, number>> = {};

    batches.forEach(batch => {
        const errors = getErrors(sortingErrors, batch.results);
        Object.entries(errors).forEach(([errorType, count]) => {
            const type = errorType as TrashType;
            if (!aggregatedErrors[type]) {
                aggregatedErrors[type] = 0;
            }
            aggregatedErrors[type]! += count; 
        });
    });

    return aggregatedErrors;
}

/**
 * Aggregate the counts of errors from a list of batches by date
 */
export function getAggregatedErrorsByDate(sortingErrors: TrashType[], batches: Batch[]): Record<string, Partial<Record<TrashType, number>>> {
    const aggregatedErrors: Record<string, Partial<Record<TrashType, number>>> = {};

    batches.forEach(batch => {
        const errors = getErrors(sortingErrors, batch.results);
        const date = moment(batch.timestamp, 'x').format('YYYY-MM-DD'); // Extract the date part as 'YYYY-MM-DD'

        if (!aggregatedErrors[date]) {
            aggregatedErrors[date] = {};
        }

        Object.entries(errors).forEach(([errorType, count]) => {
            const type = errorType as TrashType;
            if (!aggregatedErrors[date][type]) {
                aggregatedErrors[date][type] = 0;
            }
            aggregatedErrors[date][type]! += count; 
        });
    });

    return aggregatedErrors;
}

/**
 * For some partners, yellow garbage bags are sorting errors, whereas they're not for others.
 * Therefore we mush merge dynamically some subclasses into larger ones, depending on the partner.
 */
export function getMergingWasteMapping(): MergingWasteMapping {
    return { ...DEFAULT_MERGING_WASTE_MAPPING, };
}

/**
 * Cluster some waste count by merging classes that go together,
 * to create object with keys that can be displayed to the user.
 * For example, the AI detects "big cardboards", but clients consider them as "recyclable waste",
 * so we merge the counts for both classes.
 */
export function mergeTrashCount(mergingWasteMapping: MergingWasteMapping, count: TrashCount): TrashCount;
export function mergeTrashCount(mergingWasteMapping: MergingWasteMapping, count: Partial<TrashCount>): Partial<TrashCount>;
export function mergeTrashCount(mergingWasteMapping: MergingWasteMapping, count: TrashCount | Partial<TrashCount>) {
    let mergedCount: TrashCount | Partial<TrashCount> = {
        ...omit(count, Object.keys(mergingWasteMapping)),
    };

    Object.entries(mergingWasteMapping).forEach(([subType, parentType]) => {
        const subKey = subType as TrashType;
        const subCount = count[subKey];
        if (subCount) mergedCount[parentType] = (mergedCount[parentType] || 0) + subCount;
    });

    return mergedCount;
}

/** List of classes of sorting errors that are displayed to the client. */
export function getDisplayedErrorsClasses(sortingErrors?: TrashType[], mergingWasteMapping?: MergingWasteMapping) {
    const errorsClasses = sortingErrors ?? getSortingErrorClasses();
    const mergingMapping = mergingWasteMapping ?? getMergingWasteMapping();

    return errorsClasses.filter(sortingError => !Object.keys(mergingMapping).includes(sortingError));
}

/**
 * Sum 2 counts of instances of classes of waste into a single one.
 * @returns A new BatchResults whose values are the sum of both BatchResults passed as parameter.
 */
export function sumResults(results1: BatchResults, results2: BatchResults): BatchResults {
    const summedResults: BatchResults = {};
    Object.entries(results1).forEach(([k, value]) => {
        const key = k as TrashType;
        summedResults[key] = (summedResults[key] || 0) + (value || 0);
    });
    Object.entries(results2).forEach(([k, value]) => {
        const key = k as TrashType;
        summedResults[key] = (summedResults[key] || 0) + (value || 0);
    });
    return summedResults;
}
