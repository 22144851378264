import moment from 'moment';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { configureTranslations } from './locales/translations';
import reportWebVitals from './reportWebVitals';

import { initializeApp } from "firebase/app";
import { connectAuthEmulator, getAuth } from 'firebase/auth';
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';
import { connectStorageEmulator, getStorage } from 'firebase/storage';


moment.locale('fr');

configureTranslations();


initializeApp(require(`./firebase_config/${process.env.REACT_APP_FIREBASE_CONFIG}`));

if (process.env.REACT_APP_IS_LOCALHOST) { // connect to local Firebase emulators
  connectAuthEmulator(getAuth(), "http://localhost:9099", { disableWarnings: true });
  connectFirestoreEmulator(getFirestore(), "localhost", 8080);
  connectStorageEmulator(getStorage(), "localhost", 9199);
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
