import { MarkerClusterer, Renderer, type Marker } from '@googlemaps/markerclusterer';
import { useMap } from '@vis.gl/react-google-maps';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DANGER_COLORS } from '../../helpers/draw';
import { AddressStatsPointFeature, DangerLevel } from '../../helpers/geo';
import { getBatchKey } from '../../helpers/strings';
import AddressMarker from './AddressMarker';

export type ClusteredTreeMarkersProps = {
    points: AddressStatsPointFeature[];
    onMarkerClick: (addressKey: string, position?: google.maps.LatLngLiteral, providerIDs?: string[],) => void;
};

/**
 * Determines the color of the cluster based on the aggregated danger level of its markers.
 * 
 * @param {number} totalErrors - The total number of errors for the cluster.
 * @returns {string} The color representing the danger level.
 */
const getClusterColor = (totalErrors: number): string => {
    if (totalErrors >= parseInt(DangerLevel.VERY_HIGH)) return DANGER_COLORS[11];
    if (totalErrors >= parseInt(DangerLevel.HIGH)) return DANGER_COLORS[7];
    if (totalErrors >= parseInt(DangerLevel.MEDIUM)) return DANGER_COLORS[4];
    if (totalErrors >= parseInt(DangerLevel.LOW)) return DANGER_COLORS[1];
    return DANGER_COLORS[0];
};

/**
 * Custom renderer to create custom cluster icons based on danger levels.
 */
const customClusterRenderer: Renderer = {
    render: ({ count, position, markers }) => {
        let totalErrors = 0;

        if (markers) {
            markers.forEach(marker => {
                const point = (marker as any).zIndex as number ;
                if (point) {
                    totalErrors += point;
                }
            });
        }

        const color = getClusterColor(totalErrors);

        // Create a custom element for the cluster
        const clusterElement = document.createElement('div');
        clusterElement.textContent = `${count}`;
        
        Object.assign(clusterElement.style, {
            backgroundColor: color,
            opacity: '0.8',
            borderRadius: '50%',
            width: '36px',
            height: '36px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'rgba(0,0,0,0.7)',
            fontWeight: 'bold',
          });


        return new google.maps.marker.AdvancedMarkerElement({
            position,
            content: clusterElement,
        });
    }
};



/**
 * The ClusteredTreeMarkers component is responsible for integrating the
 * markers with the markerclusterer.
 */
export const ClusteredTreeMarkers = ({ points, onMarkerClick }: ClusteredTreeMarkersProps) => {
    const [markers, setMarkers] = useState<{ [key: string]: Marker }>({});


    // create the markerClusterer once the map is available and update it when
    // the markers are changed
    const map = useMap();
    const clusterer = useMemo(() => {
        if (!map) return null;

        return new MarkerClusterer({
            map,
            renderer: customClusterRenderer,
        });
    }, [map]);


    useEffect(() => {
        if (!clusterer) return;

        clusterer.clearMarkers();
        clusterer.addMarkers(Object.values(markers));
    }, [clusterer, markers]);

    // this callback will effectively get passsed as ref to the markers to keep tracks of markers currently on the map
    const setMarkerRef = useCallback((marker: Marker | null, key: string) => {
        setMarkers(markers => {
            if ((marker && markers[key]) || (!marker && !markers[key])) {
                return markers;
            }
    
            if (marker) {
                return { ...markers, [key]: marker };
            } else {
                const { [key]: _, ...newMarkers } = markers;
                return newMarkers;
            }
        });
    }, []);    
    

    return (
        <>
            {points.map((point) => {
                const [lat, lng] = point.geometry.coordinates;
                const { hereID, dangerLevel } = point.properties;
                const addressKey = getBatchKey(point.properties);
                const color = DANGER_COLORS[dangerLevel];

                return (
                    <AddressMarker
                        key={addressKey}
                        position={{ lat, lng }}
                        addressKey={addressKey}
                        onClick={onMarkerClick}
                        providerId={hereID}
                        color={color}
                        zIndex={Number(dangerLevel)}
                        setMarkerRef={setMarkerRef}
                    />
                );
            })}
        </>
    );
};