import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AddressWithErrors, MissionsData } from "../../../models/Missions";
import { LoadableContext } from "../../store";

type SelectedMissionContext = LoadableContext & {
    selectedMission: MissionsData | null;
    loadingStatus: boolean; // track when updateing the mission firestore document
};

const initialState: SelectedMissionContext = {
    loading: false, // track when get/retriveing the mission firestore document
    loadingStatus: false,
    error: null,
    selectedMission: null,
};

export const selectedMissionSlice = createSlice({
    name: 'selectedMission',
    initialState,
    reducers: {
        startLoading: (state) => {
            state.loading = true;
            state.error = null;
        },
        startLoadingStatus: (state) => {
            state.loadingStatus = true;
            state.error = null;
        },
        setSelected: (state, { payload: mission }: PayloadAction<MissionsData | null>) => {
            state.loading = false;
            state.selectedMission = mission;
        },
        setError: (state, { payload }: PayloadAction<string>) => {
            state.loading = false;
            state.error = payload;
        },
        clearSelected: (state) => {
            state.selectedMission = null;
        },
        stopLoading: (state) => {
            state.loading = false;
            state.error = null;
        },
        stopLoadingStatus: (state) => {
            state.loadingStatus = false;
            state.error = null;
        },
        updateMissionAddresses: (state, { payload: addresses }: PayloadAction<AddressWithErrors[]>) => {
            if (state.selectedMission) {
                state.selectedMission.addresses = addresses;
            }
        },
    },
});

export const SelectedMissionActions = selectedMissionSlice.actions;

const SelectedMissionReducer = selectedMissionSlice.reducer;

export default SelectedMissionReducer;
