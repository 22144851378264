import { GpsFixedRounded } from '@mui/icons-material';
import { ButtonBase } from '@mui/material';
import { ControlPosition, MapControl } from '@vis.gl/react-google-maps';

type CustomRecenterControlProps = {
    controlPosition: ControlPosition;
    onRecenter: () => void;
};

export const CustomRecenterControl = ({
    controlPosition,
    onRecenter
}: CustomRecenterControlProps) => {
    return (
        <MapControl position={controlPosition}>
            <ButtonBase
                onClick={onRecenter}
                sx={{
                    bgcolor: '#fff', 
                    padding: '7px', 
                    margin: '10px',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                    borderRadius: '4px',
                }}>
                <GpsFixedRounded sx={{ fontSize: 27 }} color="info" />
            </ButtonBase>
        </MapControl>
    );
};
