import { Box } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TrashType } from "../../constants/trash";
import { Namespace } from "../../locales/translations";
import TrashColorSquare from "./TrashColorSquare";

type CustomLegendProps = {
    keys: TrashType[];
};

const CustomLegend: React.FC<CustomLegendProps> = ({ keys }) => {
    const { t } = useTranslation([Namespace.WASTES]);

    return (
        <Box
            display="flex"
            flexWrap="wrap"
            justifyContent="center"
            mt={2}
            mb={2}
        >
            {keys.map(key => (
                <Box
                    key={key}
                    display="flex"
                    alignItems="center"
                    mr={2}
                    mb={1}
                >
                    <TrashColorSquare trashType={key} />
                    <Box ml={1}>{t(key)}</Box>
                </Box>
            ))}
        </Box>
    );
};

export default CustomLegend;
